  // Function to check if a staff member is on holiday
export const isOnHoliday = (staffId, day, staffHolidays) => {
  const staffHoliday = staffHolidays.find(holiday =>
    holiday.staff_id === staffId &&
    holiday.status === 'Approved' &&
    new Date(day) >= new Date(holiday.start) &&
    new Date(day) < new Date(holiday.end)
  );
  return staffHoliday ? 'H:Y' : 'H:N';
};

// Function to get actual hours based on log events
export const actualHours = (staffId, day, staffLogEvents) => {
  const logEvent = staffLogEvents.find(event =>
    event.staff_id === staffId &&
    new Date(event.created).toDateString() === new Date(day).toDateString()
  );

  if (logEvent) {
    if (logEvent.log_status === 'Logged in') {
      return 'A0'; // Still logged in
    } else if (logEvent.log_status === 'Logged out') {
      const hoursOnSite = logEvent.hoursonsite || 0;
      const breakLength = logEvent.breaklength || 0;
      const adjustedHours = hoursOnSite - breakLength;
      return `A${adjustedHours.toFixed(1)}`; // Return formatted hours
    }
  }
  return 'A0'; // Default case if no log event found
};

// Get the staff member's name
export const getName = (staffId, staffMembers) => {
  const staff = staffMembers.find(member => member._id === staffId);
  return staff ? `${staff.firstName} ${staff.lastName} (${staff.role} at ${staff.default_location_name})` : ''; // Return staff name or empty string
};


// Get S1 value for a given staff member and month
export const getS1 = (staffId, dayOfWeek, weekOfMonth, staffMembers) => {
  const staff = staffMembers.find(member => member._id === staffId);
  if (staff && staff.dailyEntries) {
    const entries = staff.dailyEntries.filter(entry => 
      entry.weekNumber === weekOfMonth && entry.dayOfWeek === dayOfWeek
    );
    const totalHours = entries.reduce((total, entry) => entry.hoursworked > 0 ? total + entry.hoursworked : total, 0);
    return totalHours > 0 ? totalHours : ''; // Sum up hours worked or return empty string if 0 or -1
  }
  return ''; // Return empty string if no entry is found
};

// Get S2 value for a given staff member and month
export const getS2 = (staffId, month, staffMembers) => {
  const staff = staffMembers.find(member => member._id === staffId);
  if (staff && staff.dailyEntries) {
    const entries = staff.dailyEntries.filter(entry => new Date(entry.date).getMonth() === month - 1);
    const numberOfEntries = entries.length;
    return numberOfEntries > 0 ? numberOfEntries : ''; // Number of entries or return empty string if 0
  }
  return '';
};

// Get S3 value for a given staff member and month
export const getS3 = (staffId, month, staffMembers) => {
  const staff = staffMembers.find(member => member._id === staffId);
  if (staff && staff.dailyEntries) {
    const entries = staff.dailyEntries.filter(entry => new Date(entry.date).getMonth() === month - 1);
    const maxHours = entries.length > 0 ? Math.max(...entries.map(entry => entry.hoursworked)) : 0;
    return maxHours > 0 ? maxHours : ''; // Max hours worked or return empty string if 0 or -1
  }
  return '';
};

// Retrieve working pattern for staff
export const getWorkingPatternHours = (staffId, dayOfWeek, weekOfMonth, staffMembers) => {
  const staff = staffMembers.find(member => member._id === staffId);
  if (staff && staff.dailyEntries) {
    const entry = staff.dailyEntries.find(e =>
      e.dayOfWeek === dayOfWeek &&
      e.weekNumber === weekOfMonth
    );
    return entry && entry.hoursworked > 0 ? `W${entry.hoursworked}` : 'W0';
  }
  return 'W0';
};

// Get cell content for a given day, dayOfWeek, and weekOfMonth
export const getCellContent = (staffId, dayOfWeek, weekOfMonth, day, staffMembers, staffHolidays, staffLogEvents, staffSickness) => {
  let retval = '';

  retval = getWorkingPatternHours(staffId, dayOfWeek, weekOfMonth, staffMembers);

  // Check if the staff member is on holiday
  const holidayStatus = isOnHoliday(staffId, day, staffHolidays);
  retval += ` ${holidayStatus}`; // Concatenate holiday status to retval

  // Add actual hours worked
  const hoursStatus = actualHours(staffId, day, staffLogEvents);
  retval += ` ${hoursStatus}`; // Concatenate actual hours to retval
  
  // Check for sickness status
  const sicknessStatus = isSick(staffId, day, staffSickness);
  retval += ` ${sicknessStatus}`; // Concatenate sickness status to retval

  //console.log(`Cell Content for Staff ${staffId} on ${day}:`, retval);

  return retval;
};

// Function to check if a staff member is sick
const now = new Date();

export const isSick = (staffId, day, staffSickness) => {
  // Check if there's an entry where RTW_date is null
  const currentSickness = staffSickness.find(sickness =>
    new Date(day) >= new Date(sickness.created) &&
    sickness.staff_id === staffId &&
    sickness.status === 'Open' && new Date(day)< new Date(now)
  );
  
  if (currentSickness) {
    return 'S:C'; // Sick but no RTW date
  }   
  

  // Check if there's an entry where the day is between created and RTW_date
  const sicknessEntry = staffSickness.find(sickness =>
    sickness.staff_id === staffId &&
    new Date(day) >= new Date(sickness.created) &&
    ( new Date(day) < new Date(sickness.RTW_date))
  );

  return sicknessEntry ? 'S:Y' : 'S:N'; // Return Sick or Not Sick
};


