 
// Get cell details function
export const getCellInfo = async (staffId, dayOfWeek, weekOfMonth, date ,staffMembers,
  staffHolidays,
  staffLogEvents,
  staffSickness
) => {
  try {
  
 
   // Filter to get the matching staff members
   const staffDetails = staffMembers
   .filter(member => member._id === staffId);

 // Extract the daily entries for the specified week and day
 const dayDetails = staffDetails.flatMap(member =>
   member.dailyEntries?.filter(entry =>
     entry.weekNumber === weekOfMonth &&
     entry.dayOfWeek === dayOfWeek
   ) || []
 );

 // Create a new array where dailyEntries are excluded
 const staffDetailsWithoutDailyEntries = staffDetails.map(member => {
   // Create a new object for each staff member excluding the dailyEntries property
   const { dailyEntries, ...restOfMember } = member;
   return restOfMember;
 });
    // Process log events
    const logEvent = staffLogEvents.find(event =>
      event.staff_id === staffId &&
      new Date(event.created).toDateString() === date.toDateString() 
    );

    // Process holidays
    const holiday = staffHolidays.find(holiday =>
      holiday.staff_id === staffId &&
      new Date(date) >= new Date(holiday.start) &&
      new Date(date) < new Date(holiday.end)
    );

    // Process sickness
    const currentSickness = staffSickness.find(sickness =>
      sickness.staff_id === staffId &&
      new Date(date) >= new Date(sickness.created) &&
      (new Date(date) <= new Date(sickness.end) || sickness.status === 'Open')
    );

    // Build the result array
    const result = {
      staff: staffDetailsWithoutDailyEntries,
      dayDetails: dayDetails,
      logEvent: logEvent ? [logEvent] : ['No log entries'],
      holiday: holiday ? [holiday] : ['No holiday entries'],
      sickness: currentSickness ? [currentSickness] : ['No sickness entries']
    };
    
    return result;
    

  } catch (error) {
    //console.error('Error fetching cell details:', error);
    return null;
  }
};
