 
import React, { useState, useEffect, useRef } from 'react';
import './PlanningDialog.css';
import axios from 'axios';
import InputMask from 'react-input-mask'; // For input mask
import dayjs from 'dayjs';
import BookLocum from './BookLocum'; // Ensure you import your BookLocum component
import Modal from './Modal'; // Adjust the path as necessary



const PlanningDialog = ({ data, onClose, workingLimits, selectedLocation, selectedLocationName, staffMembers }) => {
  const [entries, setEntries] = useState([]);
  const [dialogDate, setDialogDate] = useState(data?.date || '');
  const [locums, setLocums] = useState([]);
  const [isAddingLocum, setIsAddingLocum] = useState(false);
  const [selectedLocum, setSelectedLocum] = useState(null);
  const [locations, setLocations] = useState([]);
  const [staffRoles, setStaffRoles] = useState([]);
  const [staffList, setStaffList] = useState([]);
  const dayOfWeek = useRef([]);
  const aL = useRef([]);
  const dialogRef = useRef(null);


  const onRefresh = async () => {
    await fetchLocums(dialogDate); // Refetch locum data
    await fetchData(); // Refetch the data to update the entries in the right panel
  };

  const fetchData = async () => {
    try {
      const holidaysData = await fetchStaffHolidays(dialogDate);
      await fetchLocums(dialogDate); // Pass dialogDate to fetchLocums
      await fetchStaffRoles();
      await fetchStaffList();
      const staffArray = staffMembers.map((member) => ({
        name: `${member.firstName} ${member.lastName}`,
        role: member.role,
        staff_id: member._id.toString(),
        dailyEntries: member.dailyEntries,
        startEnd: '',
        hoursWorked: 0,
      }));


      const weekNumber = getWeekNumber(dialogDate);

      for (let i = staffArray.length - 1; i >= 0; i--) {
        const staff = staffArray[i];
        const dailyEntry = staff.dailyEntries?.find(
          (entry) =>
            entry.weekNumber === weekNumber &&
            entry.dayOfWeek === dayOfWeek.current &&
            entry.startTime !== "00:00"
        );

        if (dailyEntry) {
          staff.startEnd = `${dailyEntry.startTime}-${dailyEntry.endTime}`;
          staff.hoursWorked = dailyEntry.hoursworked;
        } else {
          staffArray.splice(i, 1);
        }

        delete staff.dailyEntries;
      }

      staffArray.forEach((staff) => {
        const holiday = holidaysData.find((holiday) => holiday.staff_id.toString() === staff.staff_id.toString());
        if (holiday) {
          staff.startEnd = 'HOL';
          staff.hoursWorked = 0;
          staff.status = 'HOLIDAY';
        }
      });

      staffArray.sort((a, b) => {
        if (a.role !== b.role) return a.role.localeCompare(b.role);
        return a.name.localeCompare(b.name);
      });

      const groupedEntries = [];
      const roleTotals = {};
      let currentRole = '';

      staffArray.forEach((staff) => {
        if (staff.role !== currentRole) {
          if (currentRole !== '') {
            groupedEntries.push({ isTotal: true, role: currentRole, hoursWorked: roleTotals[currentRole] });
            groupedEntries.push({ isDivider: true });
          }
          currentRole = staff.role;
          roleTotals[currentRole] = 0;
        }

        roleTotals[staff.role] += staff.hoursWorked;
        groupedEntries.push(staff);
      });

      if (currentRole !== '') {
        groupedEntries.push({ isTotal: true, role: currentRole, hoursWorked: roleTotals[currentRole] });
        groupedEntries.push({ isDivider: true });
      }

      // Now handle the locums
      aL.current.forEach(locum => {
        const locumRole = locum.role; // Get locum role
        const locumName = locum.staff_name; // Get locum name

        // Validate time format and calculate hours
        if (isValidTimeFormat(locum.startTime) && isValidTimeFormat(locum.endTime)) {
          const locumHours = calculateHours(locum.startTime, locum.endTime);

          // Add locum entry to the correct role in groupedEntries
          let roleFound = false;
          for (let entry of groupedEntries) {
            if (entry.role === locumRole) {
              // Insert the new locum entry after the current role entry
              groupedEntries.splice(groupedEntries.indexOf(entry) + 1, 0, {
                name: `${locumName} (Locum)`,
                role: locumRole,
                hoursWorked: locumHours,
                startEnd: `${locum.startTime}-${locum.endTime}`,
              });

              // Find the total entry for the role and update hoursWorked
              const totalEntry = groupedEntries.find(
                (entry) => entry.isTotal && entry.role === locumRole
              );
              if (totalEntry) {
                totalEntry.hoursWorked = parseFloat((totalEntry.hoursWorked || 0) + locumHours).toFixed(1).replace(/^0+/, '');
              } else {
                console.warn(`No total entry found for role: ${locumRole}`);
              }

              roleFound = true;
              break;
            }
          }

          // If the role is not found, you may want to handle that case (e.g., log an error or add it at the end)
          if (!roleFound) {
            console.warn(`Role not found in groupedEntries: ${locumRole}`);
          }

          // If role is not found, add locum entry and a total entry
          if (!roleFound) {
            // Add the locum entry
            groupedEntries.push({
              name: `${locumName} (Locum)`,
              role: locumRole,
              hoursWorked: locumHours,
              startEnd: `${locum.startTime}-${locum.endTime}`,
            });

            // Update role total
            roleTotals[locumRole] = (roleTotals[locumRole] || 0) + locumHours;

            // Insert the total entry for the role
            groupedEntries.push({
              isTotal: true,
              role: locumRole,
              hoursWorked: roleTotals[locumRole],
            });
            groupedEntries.push({ isDivider: true }); // Optional: to separate different roles
          }
        }
      });

      //locum handling
      setEntries(groupedEntries);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    if (data?.date) {
      setDialogDate(data.date);
      // Assuming date.date is a valid date string or Date object
      const dateObject = new Date(data.date); // Create a Date object from the date
      const options = { weekday: 'short' }; // Specify options for short weekday names
      dayOfWeek.current = dateObject.toLocaleDateString('en-US', options); // Get the abbreviated day name
    }
    fetchData();
  }, [data, dialogDate, selectedLocation, staffMembers]);

  const isValidTimeFormat = (time) => {
    // Regular expression to match HH:MM format
    const timePattern = /^([01]\d|2[0-3]):([0-5]\d)$/;
    return timePattern.test(time);
  };


 

  const fetchLocations = async () => {
    try {
      const locationsUrl = 'https://heuristic-cray.194-76-27-167.plesk.page/api/locations?organisation_id=${organisationId}';
      const response = await axios.get(locationsUrl);
      setLocations(response.data);
    } catch (error) {
      console.error('Failed to load locations:', error);
    }
  };

  const calculateHours = (startTime, endTime) => {
    // Split the time strings into hours and minutes
    const [startHour, startMinute] = startTime.split(":").map(Number);
    const [endHour, endMinute] = endTime.split(":").map(Number);
  
    // Calculate the difference in hours and minutes
    const startTotalMinutes = startHour * 60 + startMinute;
    const endTotalMinutes = endHour * 60 + endMinute;
  
    // Find the total difference in minutes
    const totalMinutes = endTotalMinutes - startTotalMinutes;
  
    // Convert the total minutes difference into hours
    const hrs = totalMinutes / 60;
    
    return hrs.toFixed(1);
  };


  const fetchStaffRoles = async () => {
    try {
      const rolesUrl = 'https://heuristic-cray.194-76-27-167.plesk.page/api/staff_roles';
      const response = await axios.get(rolesUrl);
      const roleMap = response.data.reduce((map, role) => {
        const trimmedRole = role.role.trim();
        const color = role.colour;
        if (trimmedRole && color) {
          map[trimmedRole] = color;
        }
        return map;
      }, {});

      const trimmedRoles = response.data.map(role => role.role.trim());
      setStaffRoles(trimmedRoles);
    } catch (error) {
      console.error('Failed to load staff roles:', error);
    }
  };

  const BookLocumModal = ({ locum, onClose, onRefresh }) => {
    const handleClose = () => {
      onClose(); // Close the modal
      onRefresh(); // Trigger data refresh
    };
  
    return (
      <div className="modal">
        <h2>{locum ? 'Edit Locum' : 'Add Locum'}</h2>
        {/* Input fields for locum data */}
        <button onClick={handleClose}>Cancel</button>
      </div>
    );
  };
  
  
 
  const fetchStaffHolidays = async (dialogDate) => {
    try {
      const response = await axios.get(
        'https://heuristic-cray.194-76-27-167.plesk.page/api/staff_holiday_daterange?startDate=' + dialogDate + '&endDate=' + dialogDate
      );
      return response.data;
    } catch (error) {
      console.error('Error fetching staff holidays:', error);
      throw error;
    }
  };

  const getStaffOptions = () => {
    return staffList.map((staff) => ({
      value: `${staff.firstName} ${staff.lastName} (${staff.role})`,
      label: `${staff.firstName} ${staff.lastName} (${staff.role})`
    }));
  };
  

  const fetchStaffList = async () => {
    try {
      const staffUrl = `https://heuristic-cray.194-76-27-167.plesk.page/api/staff?default_location_id=${encodeURIComponent(selectedLocation)}`;
      const response = await axios.get(staffUrl);    
       setStaffList(response.data);
    } catch (error) {
      console.error('Error fetching staff:', error);
    }
  };


 


  const fetchLocums = async (dialogDate) => {
    try {
 
      const staffUrl = `https://heuristic-cray.194-76-27-167.plesk.page/api/locums?location_id=${encodeURIComponent(selectedLocation)}`;
      const response = await axios.get(staffUrl);    
      const filtered = response.data.filter(
        (locum) => new Date(locum.start) <= new Date(dialogDate) && new Date(locum.end) >= new Date(dialogDate)
      );
      
  setLocums(filtered);
  aL.current=filtered; 

} catch (error) {
  console.error('Error fetching staff:', error);
}
};

   




  const handleLocumChange = (index, field, value) => {
    const updatedLocums = [...locums];
    if (field=='name')
    updatedLocums[index][field] = value;
    setLocums(updatedLocums);
  };

  const saveLocumChanges = async () => {
    console.log('Saving locum changes...');
    await axios.post('/api/saveLocums', locums);
  };

  const cancelLocumChanges = () => {
    fetchLocums(dialogDate); // Fetch again to reset locums
  };

  const handleDragStart = (e, index) => {
    e.dataTransfer.setData('text/plain', index);
    e.dataTransfer.effectAllowed = 'move';
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = 'move';
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const fromIndex = parseInt(e.dataTransfer.getData('text/plain'), 10);
    const toIndex = parseInt(e.target.dataset.index, 10);

    if (fromIndex === toIndex) return;

    const updatedLocums = [...locums];
    const [movedLocum] = updatedLocums.splice(fromIndex, 1);
    updatedLocums.splice(toIndex, 0, movedLocum);

    setLocums(updatedLocums);
  };


  const toDateInputValue = (date) => {
    if (!date) return ''; // handle cases where the date is null/undefined
    const d = new Date(date);
    return d.toISOString().substring(0, 10); // format as YYYY-MM-DD
  };
  

  // Helper functions
  const toDateInputValues = (date) => {
    if (!(date instanceof Date)) return '';

    const adjustedDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);

    const day = adjustedDate.getDate().toString().padStart(2, '0');
    const month = (adjustedDate.getMonth() + 1).toString().padStart(2, '0');
    const year = adjustedDate.getFullYear();

    return `${day}/${month}/${year}`;  // Format as dd/mm/yyyy
  };

  const addNewLocum = (setLocums) => {
    setLocums((prevLocums) => [
      ...prevLocums,
      { name: '', role: '', start: '', end: '', startTime: '', endTime: '', comment: '', isNew: true }
    ]);
  };

 
  const getWeekNumber = (dateString) => {
    const date = new Date(dateString);
    const startDate = new Date(date.getFullYear(), date.getMonth(), 1);
    return Math.ceil((date.getDate() + startDate.getDay()) / 7) > 4 ? 1 : Math.ceil((date.getDate() + startDate.getDay()) / 7);
  };

  const getWorkingLimit = (role, type) => {
    if (!workingLimits) return 0; // Return 0 if workingLimits is not provided

    // Create the concatenated key using role and dayOfWeek
    const key = `${role}-${dayOfWeek.current}`;

    // Find the limit in workingLimits using the key
    const limit = workingLimits[key];

    // Check the type and return the appropriate value
    if (type === 'minimum') {
        return limit ? limit : 0; // Return the limit or 0 if it doesn't exist
    } else if (type === 'optimum') {
        return limit ? limit * 1.5 : 0; // Return 1.5 times the limit or 0 if it doesn't exist
    }
    
    return 0; // Default return if type is not recognized
};


// Function to format the date
const formatDate = (isoString) => {
  return dayjs(isoString).format('DD/MM/YY');
};

  const getTotalRowStyles = (total, role) => {
    const minimum = getWorkingLimit(role, 'minimum');
    const optimum = getWorkingLimit(role, 'optimum');

    if (total < minimum) {
      return { backgroundColor: 'red', color: 'white' };
    } 
    if (total >= minimum && total < optimum) {
      return { backgroundColor: 'lightgreen', color: 'black' };
    }
   if (total >=  optimum) {
      return { backgroundColor: 'darkgreen', color: 'white' };
    }

//    return { backgroundColor: 'darkgreen', color: 'white' };
  };


  const handleLocumClick = (locum) => {
    setSelectedLocum(locum);
    setIsAddingLocum(true);
  };
  

  const handleAddNewLocum = () => {
    setSelectedLocum(null); // Reset for new locum
    setIsAddingLocum(true);
  };
  
  const handleCloseModal = () => {
    setIsAddingLocum(false); // Close the modal
    setSelectedLocum(null); // Clear selected locum (if any)
    onRefresh();
  };
  

const Modal = ({ children, onClose }) => {
  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="close-button" onClick={onClose}>×</button>
        {children}
      </div>
    </div>
  );
};
  const handleClickSave = () => {
    saveLocumChanges();
  };

  const handleClickCancel = () => {
    cancelLocumChanges();
  };

    return (
    <div className="planning-dialog" ref={dialogRef}>
      <button className="close-button" onClick={onClose}>×</button>
      <h2>Planning for {formatDate(dialogDate)}</h2>
      
      <div className="planning-container">
        {/* Left Panel */}
        <div className="left-panel">
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Role</th>
                <th>Start-End</th>
                <th>Hours Worked</th>
              </tr>
            </thead>
            <tbody>
              {entries.map((entry, index) => {
                if (entry.isTotal) {
                  return (
<tr key={index} style={getTotalRowStyles(entry.hoursWorked, entry.role)}>
  <td colSpan="3">Total for {entry.role}</td>
  <td>{entry.hoursWorked}</td>
  <td>
    Min: {getWorkingLimit(entry.role, "minimum")} Opt: {getWorkingLimit(entry.role, "optimum")}
  </td>
</tr>




                  );
                }

                if (entry.isDivider) {
                  return (
                    <tr key={index} className="divider">
                      <td colSpan="4"></td>
                    </tr>
                  );
                }

                return (
                  <tr
                    key={index}
                    draggable
                    onDragStart={(e) => handleDragStart(e, index)}
                    onDragOver={handleDragOver}
                    onDrop={handleDrop}
                    data-index={index}
                  >
                    <td>{entry.name}</td>
                    <td>{entry.role}</td>
                    <td>{entry.startEnd}</td>
                    <td>{entry.hoursWorked}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <div className="right-panel">
          <h2>Locums</h2>
          <button onClick={handleAddNewLocum}>Add New Locum</button>
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Role</th>
                <th>Start Time</th>
                <th>End Time</th>
              </tr>
            </thead>
            <tbody>
              {locums.map((locum) => (
                <tr key={locum.locum_id} onClick={() => handleLocumClick(locum)}>
                  <td>{locum.staff_name}</td>
                  <td>{locum.role}</td>
                  <td>{locum.startTime}</td>
                  <td>{locum.endTime}</td>
                </tr>
              ))}
            </tbody>
          </table>
          {isAddingLocum && (
  <Modal show={isAddingLocum} onClose={handleCloseModal}>
    <BookLocum dialogDate={dialogDate} selectedLocationName={selectedLocationName} locum={selectedLocum} onClose={handleCloseModal} selectedLocation={selectedLocation} />
  </Modal>
)}

         </div>
      </div>
    </div>
  );
};

export default PlanningDialog;