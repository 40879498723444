import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import CalendarPopup from './CalendarPopup'; // Ensure Popup component is correctly located
import './Calendar.css';
import { getCellInfo } from './getCellInfos';
import { getName, getCellContent } from './functions';
import { AuthContext } from './AuthContext';

function getDaysInMonth(month, year) {
  return new Date(year, month + 1, 0).getDate();
}

function getDayOfWeek(date) {
  const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  return daysOfWeek[new Date(date).getDay()];
}

function getWeekOfMonth(date) {
  const day = new Date(date).getDate();
  const weekOfMonth = Math.ceil(day / 7);
  return weekOfMonth === 5 ? 1 : weekOfMonth;
}

function Calendar() {
  const today = new Date();
  const { organisationId } = useContext(AuthContext); // Access organisationId

  const [staffMembers, setStaffMembers] = useState([]);
  const [staffHolidays, setStaffHolidays] = useState([]);
  const [staffSickness, setStaffSickness] = useState([]);
  const [staffLogEvents, setStaffLogEvents] = useState([]);
  const [locations, setLocations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentMonth, setCurrentMonth] = useState(today.getMonth());
  const [currentYear, setCurrentYear] = useState(today.getFullYear());
  const [selectedLocation, setSelectedLocation] = useState('');
  const [popupDetails, setPopupDetails] = useState(null);
 const [staffRoles, setStaffRoles] = useState([]); // Added state for roles
   const [selectedRole, setSelectedRole] = useState(''); // Added state for role filter



  const daysInMonth = getDaysInMonth(currentMonth, currentYear);
  const totalColumns = 31;

  useEffect(() => {
    const loadData = async () => {
      try {
        const locationResponse = await axios.get('https://heuristic-cray.194-76-27-167.plesk.page/api/locations');
        const filteredLocations = locationResponse.data.filter(location => location.organisation === organisationId);
        setLocations(filteredLocations); // Set t

        const rolesResponse = await axios.get('https://heuristic-cray.194-76-27-167.plesk.page/api/staff_roles'); // Fetch roles data
        setStaffRoles(rolesResponse.data);

        const staffUrl = selectedLocation || selectedRole
        ? `https://heuristic-cray.194-76-27-167.plesk.page/api/staff?${selectedLocation ? `default_location_name=${selectedLocation}` : ''}${selectedRole ? `&role=${encodeURIComponent(selectedRole)}` : ''}`
        : `https://heuristic-cray.194-76-27-167.plesk.page/api/staff?organisation_id=${organisationId}`;
    

//        const staffUrl = selectedLocation
  //        ? `https://heuristic-cray.194-76-27-167.plesk.page/api/staff_filtered?default_location_name=${encodeURIComponent(selectedLocation)}`
    //      : 'https://heuristic-cray.194-76-27-167.plesk.page/api/staff';
        const staffResponse = await axios.get(staffUrl);
        // Filter out staff with both firstname and lastname as 'Admin'
        const filteredStaff = staffResponse.data.filter(staff =>
          !(staff.firstName === 'Admin' && staff.lastName === 'Admin')
        );
        const sortedStaffMembers = filteredStaff.sort((a, b) => a.role.localeCompare(b.role));
        setStaffMembers(sortedStaffMembers);

        const holidaysResponse = await axios.get('https://heuristic-cray.194-76-27-167.plesk.page/api/staff_holiday');
        setStaffHolidays(holidaysResponse.data);

        const sicknessResponse = await axios.get('https://heuristic-cray.194-76-27-167.plesk.page/api/staff_sickness');
        setStaffSickness(sicknessResponse.data);

        const logEventsResponse = await axios.get('https://heuristic-cray.194-76-27-167.plesk.page/api/staff_log_events');
        setStaffLogEvents(logEventsResponse.data);

      } catch (error) {
        setError('Error fetching data');
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, [currentMonth, currentYear, selectedLocation,selectedRole]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  const handleCellStyles = (cellContent, currentDate, staffId, staffLogEvents) => {
    const date = new Date(currentDate);
    const todayDate = new Date();

    let style = { backgroundColor: 'transparent', color: 'black' };
    let displayValue = '';

    if (cellContent.includes('H:Y')) {
      style.backgroundColor = 'blue';
      style.color = 'white';
    } else if (cellContent.includes('S:Y')) {
      style.backgroundColor = 'lightCoral';
      style.color = 'black';
    } else if (cellContent.includes('S:C')) {
      style.backgroundColor = 'red';
      style.color = 'white';
    }

    let act = 0;
    let wor = 0;

    const actualHoursMatch = cellContent.match(/A(\d+(\.\d+)?)/);
    if (actualHoursMatch) {
      const actualHours = parseFloat(actualHoursMatch[1]);
      if (actualHours >= 0) {
        act = actualHours.toFixed(1);
      }
    }

    const workingPatternMatch = cellContent.match(/W(\d+(\.\d+)?)/);
    if (workingPatternMatch) {
      const workingPattern = parseFloat(workingPatternMatch[1]);
      if (workingPattern > 0) {
        wor = workingPattern.toFixed(1);
      }
    }

    if (date < todayDate) {
      displayValue = act;
    } else {
      displayValue = wor;
    }

    // Add dark blue border if this is today's date
    if (
      date.getDate() === todayDate.getDate() &&
      date.getMonth() === todayDate.getMonth() &&
      date.getFullYear() === todayDate.getFullYear()
    ) {
      style.border = '2px solid darkblue';
    }

    // Check if there's a 'Logged in' status and apply light green background
    const logEvent = staffLogEvents.find(event => {
      const eventDate = new Date(event.actual_login);
      return event.staff_id === staffId && eventDate.toDateString() === date.toDateString() && event.log_status === 'Logged in';
    });

    if (logEvent) {
      style.backgroundColor = 'lightgreen';
      const hoursDifference = ((todayDate - new Date(logEvent.actual_login)) / (1000 * 60 * 60)).toFixed(1);
      displayValue = hoursDifference;
    }

    return { style, displayValue };
  };

  const handlePrevMonth = () => {
    if (currentMonth === 0) {
      setCurrentMonth(11);
      setCurrentYear(currentYear - 1);
    } else {
      setCurrentMonth(currentMonth - 1);
    }
  };

  const handleNextMonth = () => {
    if (currentMonth === 11) {
      setCurrentMonth(0);
      setCurrentYear(currentYear + 1);
    } else {
      setCurrentMonth(currentMonth + 1);
    }
  };

  const handleToday = () => {
    const today = new Date();
    setCurrentMonth(today.getMonth());
    setCurrentYear(today.getFullYear());
  };

  const handleRoleChange = (e) => {
    setSelectedRole(e.target.value);
  };
  
  const handleLocationChange = (e) => {
    setSelectedLocation(e.target.value);
  };

  const showCellInfo = async (event, staffId, dayOfWeek, weekOfMonth, currentDate) => {
    try {
      const cellRect = event.target.getBoundingClientRect();
  
      // Call the function to get details, including datasets
      const popupDetails = await getCellInfo(
        staffId,
        dayOfWeek,
        weekOfMonth,
        currentDate,
        staffMembers,
        staffHolidays,
        staffLogEvents,
        staffSickness
      );
  
      // Set the details and position for the popup
      setPopupDetails({
        details: popupDetails,
        position: { top: cellRect.top + window.scrollY, left: cellRect.left + window.scrollX }
      });
    } catch (error) {
      setError('Error fetching staff details');
    }
  };
  
  const handlePopupClose = () => {
    setPopupDetails(null);
  };

  const getTotals = () => {
    const totals = {
      wkHrs: 0,
      acHrs: 0,
      holHrs: 0,
      sickDys: 0,
    };
    const columnTotals = Array(totalColumns).fill(0);

    staffMembers.forEach(staff => {
      let wkHrsTotal = 0;
      let acHrsTotal = 0;
      let holHrsTotal = 0;
      let sickDysTotal = 0;

      Array.from({ length: totalColumns }, (_, day) => {
        const currentDate = new Date(currentYear, currentMonth, day + 1);
        const dayOfWeek = getDayOfWeek(currentDate);
        const weekOfMonth = getWeekOfMonth(currentDate);

        const cellContent = getCellContent(
          staff._id,
          dayOfWeek,
          weekOfMonth,
          currentDate,
          staffMembers,
          staffHolidays,
          staffLogEvents,
          staffSickness
        );

             // Print cellContent to the console
             console.log(`Staff ID: ${staff._id}, Date: ${currentDate.toDateString()}, Cell Content: ${cellContent}`);

        const { style, displayValue } = handleCellStyles(
          cellContent,
          currentDate,
          staff._id,
          staffLogEvents
        );

        const actualHoursMatch = cellContent.match(/A(\d+(\.\d+)?)/);
        if (actualHoursMatch) {
          const hours = parseFloat(actualHoursMatch[1]);
          acHrsTotal += hours;
        }

        const workingPatternMatch = cellContent.match(/W(\d+(\.\d+)?)/);
        if (workingPatternMatch) {
          const hours = parseFloat(workingPatternMatch[1]);
          wkHrsTotal += hours;
        }
         
        if (cellContent.includes('H:Y')) {
          const workingPatternMatch = cellContent.match(/W(\d+(\.\d+)?)/);
          if (workingPatternMatch) {
            const hours = parseFloat(workingPatternMatch[1]);
            holHrsTotal += hours;
          }
         }
        if (cellContent.includes('S:Y')) {
          const workingPatternMatch = cellContent.match(/W(\d+(\.\d+)?)/);
          if (workingPatternMatch) {
            const hours = parseFloat(workingPatternMatch[1]);
            if (hours>0){
              sickDysTotal += 1;
              }   
          }
         
        }

        const isWeekend = dayOfWeek === 'Sat' || dayOfWeek === 'Sun';

        columnTotals[day] += parseFloat(displayValue) || 0;
      });

      totals.wkHrs += wkHrsTotal;
      totals.acHrs += acHrsTotal;
      totals.holHrs += holHrsTotal;
      totals.sickDys += sickDysTotal;
    });

    return { totals, columnTotals };
  };

  const { totals, columnTotals } = getTotals();

  return (
    <div className="calendar-page">
      <div className="calendar-title">Staff Calendar</div>
      <div className="calendar-controls">
        <button onClick={handlePrevMonth} className="calendar-button">Previous Month</button>
        <span className="month-display">{`${new Date(currentYear, currentMonth).toLocaleString('default', { month: 'long' })} ${currentYear}`}</span>
        <button onClick={handleNextMonth} className="calendar-button">Next Month</button>
        <button onClick={handleToday} className="calendar-button">Today</button>
        <select onChange={handleLocationChange} value={selectedLocation} className="location-dropdown">
          <option value="">Select Location</option>
          {locations.map(location => (
            <option key={location._id} value={location.name}>
              {location.name}
            </option>
          ))}
        </select>
        <label htmlFor="role-dropdown">Role:</label> {/* Added label for role dropdown */}
  <select
    id="role-dropdown"
    onChange={handleRoleChange}
    value={selectedRole}
  >
    <option value="">All Roles</option>
    {staffRoles.map(role => ( // Populate role options
      <option key={role._id} value={role.role}>
        {role.role}
      </option>
    ))}
  </select>
      </div>
      {popupDetails && (
        <CalendarPopup
          details={popupDetails.details}
          onClose={handlePopupClose}
          position={popupDetails.position}
        />
      )}
      <div className="calendar-grid">
        {/* Header Row */}
        <div className="calendar-cell label-cell">Name</div>
        {Array.from({ length: totalColumns }, (_, day) => (
          <div key={day + 1} className="calendar-cell">
            {day + 1 <= daysInMonth ? day + 1 : ''}
          </div>
        ))}
        <div className="calendar-cell label-cell">Planned</div>
        <div className="calendar-cell label-cell">Actual</div>
        <div className="calendar-cell label-cell">Holidays</div>
        <div className="calendar-cell label-cell">Sickness</div>

        {/* Data Rows */}
        {staffMembers.map((staff) => {
          let wkHrsTotal = 0;
          let acHrsTotal = 0;
          let holHrsTotal = 0;
          let sickDysTotal = 0;

          return (
            <React.Fragment key={staff._id}>
              <div className="calendar-cell">
                {getName(staff._id, staffMembers)}
              </div>
              {Array.from({ length: totalColumns }, (_, day) => {
                const currentDate = new Date(currentYear, currentMonth, day + 1);
                const dayOfWeek = getDayOfWeek(currentDate);
                const weekOfMonth = getWeekOfMonth(currentDate);

                const cellContent = getCellContent(
                  staff._id,
                  dayOfWeek,
                  weekOfMonth,
                  currentDate,
                  staffMembers,
                  staffHolidays,
                  staffLogEvents,
                  staffSickness
                );

                const { style, displayValue } = handleCellStyles(
                  cellContent,
                  currentDate,
                  staff._id,
                  staffLogEvents
                );

                const actualHoursMatch = cellContent.match(/A(\d+(\.\d+)?)/);
                if (actualHoursMatch) {
                  const hours = parseFloat(actualHoursMatch[1]);
                  acHrsTotal += hours;
                }

                const workingPatternMatch = cellContent.match(/W(\d+(\.\d+)?)/);
                if (workingPatternMatch) {
                  const hours = parseFloat(workingPatternMatch[1]);
                  wkHrsTotal += hours;
                }

                if (cellContent.includes('H:Y')) {
                  const workingPatternMatch = cellContent.match(/W(\d+(\.\d+)?)/);
                  if (workingPatternMatch) {
                    const hours = parseFloat(workingPatternMatch[1]);
                    holHrsTotal += hours;
                  }
  
              
                }
                if (cellContent.includes('S:Y')) {
                  const workingPatternMatch = cellContent.match(/W(\d+(\.\d+)?)/);
                  if (workingPatternMatch) {
                    const hours = parseFloat(workingPatternMatch[1]);
                    if (hours>0){
                      sickDysTotal += 1;
                      }   
                  }
                 
                }
                const isWeekend = dayOfWeek === 'Sat' || dayOfWeek === 'Sun';

                return (
                  <div
                    key={`day-${staff._id}-${day + 1}`}
                    className="calendar-cell"
                    style={{ ...style, ...(isWeekend ? { backgroundColor: 'grey', color: 'white' } : {}) }}
                    onClick={(event) =>
                      showCellInfo(event, staff._id, dayOfWeek, weekOfMonth, currentDate)
                    }
                  >
                    {displayValue}
                  </div>
                );
              })}
              <div className="calendar-cell">
                {wkHrsTotal.toFixed(1)}
              </div>
              <div className="calendar-cell">
                {acHrsTotal.toFixed(1)}
              </div>
              <div className="calendar-cell">
                {holHrsTotal}
              </div>
              <div className="calendar-cell">
                {sickDysTotal}
              </div>
            </React.Fragment>
          );
        })}

        {/* Totals Row */}
        <div className="calendar-cell bottom-row">Totals</div>
        {columnTotals.map((total, index) => (
          <div key={`total-${index}`} className="calendar-cell bottom-row">
            {total.toFixed(1)}
          </div>
        ))}
        <div className="calendar-cell bottom-row">
          {totals.wkHrs.toFixed(1)}
        </div>
        <div className="calendar-cell bottom-row">
          {totals.acHrs.toFixed(1)}
        </div>
        <div className="calendar-cell bottom-row">
          {totals.holHrs}
        </div>
        <div className="calendar-cell bottom-row">
          {totals.sickDys}
        </div>
      </div>
    </div>
  );
};

export default Calendar;
