import './booklocum.css';
import React, { useEffect, useState } from 'react';
import axios from 'axios';

const BookLocum = ({ dialogDate, selectedLocationName,selectedLocation, locum, onClose }) => {
  const [staffRoles, setStaffRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState('');
  const [staffMembers, setStaffMembers] = useState([]);
  const [staffHolidays, setStaffHolidays] = useState([]);
  const [locations, setLocations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedStaff, setSelectedStaff] = useState({ name: '', email: '', staff_id: '', comment: '' });
  


  const [startDate, setStartDate] = useState(dialogDate.toISOString().split('T')[0]); // Today's date in YYYY-MM-DD format
  const [endDate, setEndDate] = useState(dialogDate.toISOString().split('T')[0]); // Today's date in YYYY-MM-DD format
  const [startTime, setStartTime] = useState('09:00'); // Default start time
  const [endTime, setEndTime] = useState('17:00'); // Default end time



  useEffect(() => {
    const loadData = async () => {
      try {
        // Fetch roles and staff data
        const rolesResponse = await axios.get('https://heuristic-cray.194-76-27-167.plesk.page/api/staff_roles');
        setStaffRoles(rolesResponse.data);
  
        const staffUrl = selectedRole
          ? `https://heuristic-cray.194-76-27-167.plesk.page/api/staff?${selectedRole ? `&role=${encodeURIComponent(selectedRole)}` : ''}`
          : 'https://heuristic-cray.194-76-27-167.plesk.page/api/staff';
  
        const staffResponse = await axios.get(staffUrl);
        const staffData = staffResponse.data;
  
        const holidaysResponse = await axios.get('https://heuristic-cray.194-76-27-167.plesk.page/api/staff_holiday');
        setStaffHolidays(holidaysResponse.data);
  
        // Fetch locum data
        const locumsResponse = await axios.get('https://heuristic-cray.194-76-27-167.plesk.page/api/locums');
        const locumsData = locumsResponse.data;
  
        // Perform filtering based on the date range
        const filteredStaff = filterStaffByDateAndAvailability(
          staffData, 
          locumsData, 
          startDate, 
          endDate, 
          startTime, 
          endTime
        );
  
        setStaffMembers(filteredStaff);
      } catch (error) {
        setError('Error fetching data');
      } finally {
        setLoading(false);
      }
    };
  
    loadData();
  }, [selectedRole, startDate, endDate, startTime, endTime]);
    // Update selected staff details

    const filterStaffByDateAndAvailability = (staffMembers, locums, startDate, endDate, startTime, endTime) => {
      // Get the week number and day of the week for the start date
      const weekNumber = getWeekOfMonth(startDate);
      const dayOfWeek = new Intl.DateTimeFormat('en-US', { weekday: 'short' }).format(new Date(startDate));
    
      // Filter staff members
      return staffMembers.filter(member => {
        const dailyEntries = member.dailyEntries || [];
        const matchingEntries = dailyEntries.filter(entry =>
          entry.weekNumber === weekNumber && entry.dayOfWeek === dayOfWeek && !entry.startTime && !entry.endTime
        );
    
        // If the staff member has no dailyEntries for that week/day, or has empty start/end times, they're available
        const isAvailableBasedOnDailyEntries = matchingEntries.length > 0 || dailyEntries.length === 0;
    
        // Check locums to ensure no time conflicts
        const hasLocumConflict = locums.some(locum =>
          locum.staff_id === member._id &&
          (new Date(locum.start) <= new Date(startDate) && new Date(locum.end) >= new Date(endDate)) &&
          (locum.startTime < endTime && locum.endTime > startTime) // Time conflict check
        );
    
        return isAvailableBasedOnDailyEntries && !hasLocumConflict;
      });
    };
    
    function getWeekOfMonth(date) {
      const day = new Date(date).getDate();
      const weekOfMonth = Math.ceil(day / 7);
      return weekOfMonth === 5 ? 1 : weekOfMonth;
    } 

    const handleStaffChange = (e) => {
      const staffId = e.target.value;
      const staff = staffMembers.find(member => member._id === staffId);
      if (staff) {
        setSelectedStaff({
          comment: staff.comment || '', 
          name: `${staff.firstName} ${staff.lastName}`, 
          email: staff.email, 
          staff_id: staff._id 
        });
      }
    };
    

  const handleSave = async () => {
    try {
      // Construct the locum object
      const newLocum = {
        staff_id: selectedStaff.staff_id,
        start: startDate,
        end: endDate,
        startTime: startTime.toString(),
        endTime: endTime.toString(),
        staff_name: selectedStaff.name,
        email: selectedStaff.email,
        role: selectedRole,
        comment: selectedStaff.comment,
        location_id: selectedLocation, // Set location_id to selectedLocation
      };

      // Save the new locum to the collection
      await axios.post('https://heuristic-cray.194-76-27-167.plesk.page/api/locums', newLocum);

      // Send acknowledgment email if email address exists
      if (selectedStaff.email) {
        await axios.post('https://heuristic-cray.194-76-27-167.plesk.page/api/send_email', {
          to: selectedStaff.email,
          subject: 'Locum Booking Confirmation',
          text: `\nThis is to confirm your locum booking at ${selectedLocationName}.\n\nDetails:\nRole: ${selectedRole}\nName: ${selectedStaff.name}\nStart Date: ${startDate}\nEnd Date: ${endDate}\nStart Time: ${startTime}\nEnd Time: ${endTime}\n\nIf you have any queries please contact us.`,
        });
      }

      console.log('Locum saved successfully!');
      onClose(); // Close the modal after saving
    } catch (error) {
      console.error('Error posting new locum:', error);
      onClose();
    }
  };




  return (
    <>
      {/* Overlay */}
      <div className="modal-overlay" onClick={onClose}></div>

      {/* Floating Modal Dialog */}
      <div className="book-locum">
        <div className="header-container">
          <h2 className="title">Add New Locum</h2>
        </div>

        {/* Form fields */}
        <div className="locum-field">
          <label>Select Role:</label>
          <select value={selectedRole} onChange={(e) => setSelectedRole(e.target.value)}>
            <option value="" disabled>Select a role</option>
            {staffRoles.map((role) => (
              <option key={role.id} value={role.role}>{role.role}</option>
            ))}
          </select>
        </div>


        <div className="locum-field">
          <label>Start Date:</label>
          <input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
          <span>-</span>
          <label>End Date:</label>
          <input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
        </div>

        <div className="locum-field">
          <label>Start Time:</label>
          <input type="time" value={startTime} onChange={(e) => setStartTime(e.target.value)} />
          <span>-</span>
          <label>End Time:</label>
          <input type="time" value={endTime} onChange={(e) => setEndTime(e.target.value)} />
        </div>


        <div className="locum-field">
          <label>Name:</label>
          <select value={selectedStaff.staff_id} onChange={handleStaffChange}>
            <option value="" disabled>Select a staff member</option>
            {staffMembers.filter(member => member.role === selectedRole).map((member) => (
              <option key={member._id} value={member._id}>{`${member.firstName} ${member.lastName}`}</option>
            ))}
          </select>
        </div>

        <div className="locum-field">
          <label>Email:</label>
          <input
            type="email"
            value={selectedStaff.email}
            onChange={(e) => setSelectedStaff({ ...selectedStaff, email: e.target.value })}
            placeholder="Email Address"
          />
        </div>

        <div className="locum-field">
          <label>Comment:</label>
          <input 
            type="text" 
            value={selectedStaff.comment} 
            onChange={(e) => setSelectedStaff({ ...selectedStaff, comment: e.target.value })} 
            placeholder="Enter comment" 
          />
        </div>


        <div className="button-container">
          <button onClick={handleSave}>Save</button>
          <button onClick={onClose}>Cancel</button>
        </div>
      </div>
    </>
  );


};

export default BookLocum;
