import React, { useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { format } from 'date-fns';
import { AuthContext } from './AuthContext';

const Announcements = () => {
  const { organisationId } = useContext(AuthContext); // Access organisationId

  const [locations, setLocations] = useState([]);
  const [roles, setRoles] = useState([]);
  const [announcements, setAnnouncements] = useState([]);
  const [selectedAnnouncement, setSelectedAnnouncement] = useState(null);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [showDialog, setShowDialog] = useState(false);
  const [announcementMessage, setAnnouncementMessage] = useState('');
  const [displayFrom, setDisplayFrom] = useState(format(new Date(), 'yyyy-MM-dd')); // Default to today's date
  const [displayUntil, setDisplayUntil] =  useState(format(new Date(), 'yyyy-MM-dd'));

  // Fetch locations
  const fetchLocations = async () => {
    try {
      const response = await axios.get('https://heuristic-cray.194-76-27-167.plesk.page/api/locations?organisation_id=${organisationId}');
      setLocations(response.data);
    } catch (error) {
      console.error('Error fetching locations:', error);
    }
  };

  // Fetch roles
  const fetchRoles = async () => {
    try {
      const response = await axios.get('https://heuristic-cray.194-76-27-167.plesk.page/api/staff_roles');
      setRoles(response.data);
    } catch (error) {
      console.error('Error fetching roles:', error);
    }
  };

  // Fetch announcements
  const fetchAnnouncements = async (orgId) => {
    try {
      const response = await axios.get(`https://heuristic-cray.194-76-27-167.plesk.page/api/announcements?organisation_id=${orgId}`);
      setAnnouncements(response.data);
    } catch (error) {
      console.error('Error fetching announcements:', error);
    }
  };

  useEffect(() => {
    fetchLocations();
    fetchRoles();
    fetchAnnouncements(organisationId);
  }, []);

  // Handle 'Select All Locations' button
  const selectAllLocations = () => {
    setSelectedLocations(locations.map(loc => loc.name));
  };

  // Handle 'Select All Roles' button
  const selectAllRoles = () => {
    setSelectedRoles(roles.map(role => role.role));
  };

  // Handle form submission to create or update an announcement
  const createOrUpdateAnnouncement = async () => {
 
 try {
    if (selectedAnnouncement) {
   
    const newAnnouncement = {
      _id: selectedAnnouncement._id,
      organisation_id: organisationId,
        message: announcementMessage,
        display_from: displayFrom,
        display_until: displayUntil,
        locations: selectedLocations,
        roles: selectedRoles,
      };
      await axios.post(`https://heuristic-cray.194-76-27-167.plesk.page/api/announcement_update`, newAnnouncement);
       
    }
    else  {
      const newAnnouncement = {
         organisation_id: organisationId,
          message: announcementMessage,
          display_from: displayFrom,
          display_until: displayUntil,
          locations: selectedLocations,
          roles: selectedRoles,
        };
        await axios.post('https://heuristic-cray.194-76-27-167.plesk.page/api/announcements', newAnnouncement);

    }      
       
    
 
      fetchAnnouncements(organisationId);
      setShowDialog(false); // Close the dialog after successful creation or update
      resetForm();
    } catch (error) {
      console.error('Error creating or updating announcement:', error);
    }
  };

  // Reset form fields when closing or after submission
  const resetForm = () => {
    setSelectedAnnouncement(null);
    setAnnouncementMessage('');
    setDisplayFrom(format(new Date(), 'yyyy-MM-dd'));
    setDisplayUntil(format(new Date(), 'yyyy-MM-dd'));
    setSelectedLocations([]);
    setSelectedRoles([]);
  };

  // Handle edit button click
  const handleEditClick = (announcement) => {
    setSelectedAnnouncement(announcement);
    setAnnouncementMessage(announcement.message);
    setDisplayFrom(format(new Date(announcement.display_from), 'yyyy-MM-dd'));
    setDisplayUntil(format(new Date(announcement.display_until), 'yyyy-MM-dd'));
    setSelectedLocations(announcement.locations);
    setSelectedRoles(announcement.roles);
    setShowDialog(true); // Show dialog with populated data
  };

  return (
    <div>
      <h1>Announcements</h1>
      <button onClick={() => setShowDialog(true)}>Add New Announcement</button>
      <table border="1" style={{ marginTop: '20px', width: '100%', borderCollapse: 'collapse' }}>
        <thead>
          <tr>
            <th>Display From</th>
            <th>Display For (Days)</th>
            <th>Message</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {announcements.map((announcement) => (
            <tr key={announcement._id}>
              <td>{new Date(announcement.display_from).toLocaleDateString('en-GB')}</td>
              <td>{new Date(announcement.display_until).toLocaleDateString('en-GB')}</td>
               <td>{announcement.message}</td>
              <td>
                <button onClick={() => handleEditClick(announcement)}>Edit</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {showDialog && (
        <div className="popup">
          <h2>{selectedAnnouncement ? 'Edit Announcement' : 'Add Announcement'}</h2>

          <div>
            <label>Message:</label>
            <textarea
              value={announcementMessage}
              onChange={(e) => setAnnouncementMessage(e.target.value)}
              rows="4"
              cols="50"
            />
          </div>

          <div>
            <label>Display From:</label>
            <input
              type="date"
              value={displayFrom}
              onChange={(e) => setDisplayFrom(e.target.value)}
            />
          </div>

          <div>
            <label>Display Until:</label>
            <input
              type="date"
              value={displayUntil}
              onChange={(e) => setDisplayUntil(e.target.value)}
            />
          </div>

          <div>
            <h3>Locations</h3>
            <button onClick={selectAllLocations}>Select All Locations</button>
            {locations.map((location) => (
              <div key={location._id}>
                <input
                  type="checkbox"
                  checked={selectedLocations.includes(location.name)}
                  onChange={() =>
                    setSelectedLocations((prev) =>
                      prev.includes(location.name)
                        ? prev.filter((loc) => loc !== location.name)
                        : [...prev, location.name]
                    )
                  }
                />
                {location.name}
              </div>
            ))}
          </div>

          <div>
            <h3>Roles</h3>
            <button onClick={selectAllRoles}>Select All Roles</button>
            {roles.map((role) => (
              <div key={role._id}>
                <input
                  type="checkbox"
                  checked={selectedRoles.includes(role.role)}
                  onChange={() =>
                    setSelectedRoles((prev) =>
                      prev.includes(role.role)
                        ? prev.filter((rol) => rol !== role.role)
                        : [...prev, role.role]
                    )
                  }
                />
                {role.role}
              </div>
            ))}
          </div>

          <button onClick={createOrUpdateAnnouncement}>
            {selectedAnnouncement ? 'Update' : 'Create'}
          </button>
          <button onClick={() => { setShowDialog(false); resetForm(); }}>Cancel</button>
        </div>
      )}
    </div>
  );
};

export default Announcements;
