import React from 'react';
import './floatingPopup.css';

const FloatingPopup = ({ data, onClose }) => {
  // Directly set position via props or use static values
  const position = { top: '10px', left: '10px' }; // Static position or set from props

  if (!data) return null;

  // Format the entries as a single string with newlines
  const formattedEntries = data.holidayEntries.map((entry) => {
    return `${entry.staff_name} (${entry.role}) - ${new Date(entry.start).toLocaleDateString('en-GB')} to ${new Date(entry.end).toLocaleDateString('en-GB')} ${entry.type === 'Part day' ? `from ${entry.from_time} to ${entry.to_time}` : ''}`;
  }).join('\n');

  return (
    <div
      className="floating-popup"
      style={{ top: position.top, left: position.left }}
    >
       <pre className="popup-content">{formattedEntries || 'No entries available'}</pre>
    </div>
  );
};

export default FloatingPopup;
