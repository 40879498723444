import React, {useContext, useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Button, TextField } from '@mui/material';
import { AuthContext } from './AuthContext';


const CrossBranch = () => {
    const { organisationId } = useContext(AuthContext); // Access organisationId

    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [exportData, setExportData] = useState([]);

    // Fetch data from the staff_log_events dataset
    const fetchData = async () => {
        try {
            const response = await fetch('/api/staff_log_events'); // Adjust the route as needed
            const data = await response.json();
            filterData(data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    // Filter data based on inputs and criteria
    const filterData = (data) => {
        const filtered = data.filter(
            item => 
                item.log_status === 'Logged off' &&
                item.default_locationId !== item.locationId &&
                item.date >= startDate &&
                item.date <= endDate
        );
        setFilteredData(filtered);
        calculateTotals(filtered);
    };

    // Handle date changes
    const handleStartDateChange = (event) => setStartDate(event.target.value);
    const handleEndDateChange = (event) => setEndDate(event.target.value);

    // Calculate totals row
    const calculateTotals = (data) => {
        const totals = data.reduce((acc, item) => {
            acc[item.locationByLatLong] = acc[item.locationByLatLong] || { stdhrspay: 0, overtimepay: 0 };
            acc[item.locationByLatLong].stdhrspay += item.stdhrspay;
            acc[item.locationByLatLong].overtimepay += item.overtimepay;
            return acc;
        }, {});
        setExportData([...data, { staff_name: 'Total', ...totals }]);
    };

    // Fetch data when start or end date changes
    useEffect(() => {
        if (startDate && endDate) fetchData();
    }, [startDate, endDate]);

    // Prepare columns for DataGrid
    const columns = [
        { field: 'staff_name', headerName: 'Staff Name', width: 150, sortable: true },
        { field: 'defaultLocationName', headerName: 'Default Location', width: 150, sortable: true },
        ...[...new Set(filteredData.map(item => item.locationByLatLong))].map(location => ({
            field: location,
            headerName: location,
            width: 200,
            renderCell: (params) => (
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <span>{params.row[location]?.stdhrspay || 0}</span>
                    <span>{params.row[location]?.overtimepay || 0}</span>
                </div>
            )
        }))
    ];

    // Export function for grid data
    const handleExport = () => {
        const csvRows = [
            ['Start Date:', startDate, 'End Date:', endDate],
            columns.map(col => col.headerName),
            ...exportData.map(row => columns.map(col => row[col.field] || ''))
        ];

        const csvContent = "data:text/csv;charset=utf-8," + csvRows.map(e => e.join(",")).join("\n");
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "cross_branch_data.csv");
        document.body.appendChild(link); 
        link.click();
        document.body.removeChild(link);
    };

    return (
        <div style={{ padding: 20 }}>
            <h2>Cross Branch Data</h2>
            <div style={{ display: 'flex', gap: 20, marginBottom: 20 }}>
                <TextField
                    label="Start Date"
                    type="date"
                    value={startDate}
                    onChange={handleStartDateChange}
                    InputLabelProps={{ shrink: true }}
                />
                <TextField
                    label="End Date"
                    type="date"
                    value={endDate}
                    onChange={handleEndDateChange}
                    InputLabelProps={{ shrink: true }}
                />
            </div>
            <div style={{ height: 400, width: '100%', marginBottom: 20 }}>
                <DataGrid
                    rows={filteredData.map((item, index) => ({ id: index, ...item }))}
                    columns={columns}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                />
            </div>
            <Button variant="contained" color="primary" onClick={handleExport}>
                Export
            </Button>
        </div>
    );
};

export default CrossBranch;
