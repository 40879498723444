import React, { useState } from 'react';
import axios from 'axios';
import './reviewLogEvent.css'; // Adjust the path as needed
import MapDialog from './Map'; // Import the new MapDialog

const ReviewLogEvent = ({ isOpen, onClose, logEvent, fetchStaffLogEvents }) => {
  const [isLocationDialogOpen, setLocationDialogOpen] = useState(false);
  const [isLogoutDistanceDialogOpen, setLogoutDistanceDialogOpen] = useState(false);
  const [stdhrsworked, setStdHrsWorked] = useState(logEvent.stdhrsworked);
  const [overtimehrs, setOvertimeHrs] = useState(logEvent.overtimehrs);
  const [managerComment, setManagerComment] = useState(logEvent.manager_comment);
  const [holidayStatus, setHolidayStatus] = useState(logEvent.status);

  if (!isOpen) {
    setLocationDialogOpen(false);
    setLogoutDistanceDialogOpen(false);
    return null;
  }

  const formatDate = (input) => {
    if (!input) return 'Invalid Date';
    const date = (typeof input === 'string') ? new Date(input) : input;
    if (isNaN(date.getTime())) {
      return 'Invalid Date';
    }
    return date.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
  };

  const formatDateTime = (input) => {
    if (!input) return 'Invalid Date';
    const date = (typeof input === 'string') ? new Date(input) : input;
    if (isNaN(date.getTime())) {
      return 'Invalid Date';
    }
    return date.toLocaleString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    });
  };

  const handleSave = async () => {
    const updatedLogEvent = {
      _id: logEvent._id,
      stdhrsworked: stdhrsworked,
      overtimehrs: overtimehrs,
      manager_comment: managerComment,
      status: holidayStatus,

    };

    try {
      await axios.post(`https://heuristic-cray.194-76-27-167.plesk.page/api/log_event_update`, updatedLogEvent);
       
      onClose(); // Close the dialog
    } catch (error) {
      console.error('Error updating log event:', error);
    }
  };

  const renderTopLeftPanel = () => (
    <div className="panel">
      <h3>Background</h3>
      <p><strong>Staff name:</strong> {logEvent.staff_name}</p>
      <p><strong>Hour pattern:</strong> {logEvent.hourpattern}</p>
      <p><strong>Geolocate:</strong> {logEvent.usegeolocate}</p>
      <p><strong>Location:</strong> {logEvent.location}</p>
      <p><strong>Date:</strong> {formatDate(logEvent.created)}</p>
      <p><strong>Day of week:</strong> {new Date(logEvent.created).toLocaleDateString('en-US', { weekday: 'long' })}</p>
      <p><strong>Break length:</strong> {logEvent.breaklength}</p>
      <p><strong>Grace period:</strong> {logEvent.graceperiod}</p>
      <p
        style={{
          backgroundColor: logEvent.valid === 'Variance' || logEvent.valid === 'Extra session' ? 'lightcoral' : 'transparent',
        }}
      >
        <strong>Day analysis:</strong> {logEvent.valid}
      </p>
  
 {/* Moved radio buttons panel here */}
<div onChange={(e) => setHolidayStatus(e.target.value)} className="radio-group" style={{ paddingTop: '20px' }}>
  <label style={{ display: 'inline-flex', alignItems: 'center', marginRight: '20px', paddingBottom: '10px' }}>
    Completed
    <input
      type="radio"
      name="status"
      value="Completed"
      checked={holidayStatus === 'Completed'}
      style={{ marginLeft: '5px' }} // Space between label and radio button
    /> 
  </label>

  <label style={{ display: 'inline-flex', alignItems: 'center', paddingBottom: '10px' }}>
    Under Review
    <input
      type="radio"
      name="status"
      value="Under Review"
      checked={holidayStatus === 'Under Review'}
      style={{ marginLeft: '5px' }} // Space between label and radio button
    /> 
  </label>
</div>

      <div className="popup-buttons">
          <button className="confirm-button" onClick={handleSave}>Submit</button>
          <button className="cancel-button" onClick={() => { 
            onClose(); 
            setLocationDialogOpen(false); 
            setLogoutDistanceDialogOpen(false); 
          }}>Cancel</button>
        </div>


    </div>
  

);
  

 
const renderPanel = () => (
  <div className="panel">
    {/* Left Panel Content */}
    <div className="left-panel">
      <p
        style={{
          backgroundColor: logEvent.locationValidated ? 'inherit' : 'lightcoral',
        }}
      >
        <strong>Clock-in location:</strong> {logEvent.location} &nbsp;
        <span
          className={`clickable ${logEvent.login_latitude && logEvent.login_longitude ? 'highlight' : ''}`}
          onClick={() => setLocationDialogOpen(true)}
        >
          {logEvent.locationValidated ? 'Validated' : 'Not Validated'}
        </span>
      </p>

      <p
        style={{
          backgroundColor: logEvent.login_variance > logEvent.graceperiod ? 'lightcoral' : 'inherit',
        }}
      >
        <strong>Planned log in:</strong> {logEvent.planned_login} &nbsp;
        <strong>Actual log in:</strong> {formatDateTime(logEvent.actual_login)} &nbsp;
        <strong>Adjusted log in:</strong> {formatDateTime(logEvent.adjusted_login)} &nbsp;
        {logEvent.login_variance > 0 ? (
          <p><strong>Late by:</strong> {logEvent.login_variance} minutes</p>
        ) : null}
      </p>
      <p><strong>Clock-in message:</strong> {logEvent.login_message}</p>
      <p><strong>Clock-in response:</strong> {logEvent.login_comment}</p>

      <p style={{ margin: '10px 0' }}></p>

      <strong>Distance away at clock-out (metres):</strong>
      <span
        className={`clickable ${logEvent.logout_latitude && logEvent.logout_longitude ? 'highlight' : ''}`}
        onClick={() => setLogoutDistanceDialogOpen(true)}
      >
        {logEvent.logoutdistance_variance}
      </span>

      <p
        style={{
          backgroundColor: logEvent.logout_variance < 0 || logEvent.logout_variance > logEvent.graceperiod ? 'lightcoral' : 'inherit',
        }}
      >
        <strong>Planned log out:</strong> {logEvent.planned_logout} &nbsp;
        <strong>Actual log out:</strong> {formatDateTime(logEvent.actual_logout)} &nbsp;
        {logEvent.logout_variance < 0 ? (
          <>
            <strong>Logged out early by:</strong> {Math.abs(logEvent.logout_variance)} minutes
          </>
        ) : logEvent.logout_variance > logEvent.graceperiod ? (
          <>
            <strong>Logged out late by:</strong> {logEvent.logout_variance} minutes
          </>
        ) : (
          <>
            <strong></strong>
          </>
        )}
      </p>

      <p><strong>Clock-out message:</strong> {logEvent.logout_message}</p>
      <p><strong>Clock-out response:</strong> {logEvent.logout_comment}</p>

      {logEvent.overtime_requested && (
        <>
          <p style={{ backgroundColor: 'lightcoral' }}>
            <strong>Overtime requested:</strong> {logEvent.overtime_requested}
          </p>
          <p style={{ backgroundColor: 'lightcoral' }}>
            <strong>Overtime available:</strong> {logEvent.overtime_available}
          </p>
        </>
      )}
    </div>

    {/* Right Panel Content */}
    <div className="right-panel">
      {/*    <h3>Summary</h3> */}
      <p><strong>Planned hours on site (including break):</strong> {logEvent.plannedhoursonsite}</p>
      <p><strong>Actual hours on site (including break):</strong> {logEvent.hoursonsite}</p>

      <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
        <p style={{ margin: 0 }}><strong>Standard hrs claimed: </strong></p>
        <input
          type="text"
          value={stdhrsworked}
          onChange={(e) => setStdHrsWorked(e.target.value)}
          style={{ padding: '5px', width: '100px' }} // Adjust input field size if needed
        />

        <p style={{ margin: 0 }}><strong>Overtime hrs claimed: </strong></p>
        <input
          type="text"
          value={overtimehrs}
          onChange={(e) => setOvertimeHrs(e.target.value)}
          style={{ padding: '5px', width: '100px' }} // Adjust input field size if needed
        />
      </div>

      <p><strong>Manager comment:</strong></p>
      <textarea
        value={managerComment}
        onChange={(e) => setManagerComment(e.target.value)} 
        rows="4"
        cols="50"
      />
    </div>
  </div>
);


  return (
    <div className="popup-overlay">
      <div className="popup">
         <div className="popup-content">
          <div className="column">
            {renderTopLeftPanel()}
          </div>
          <div className="column">
  {renderPanel()}  {/* Replaces the old panel rendering functions */}
</div>
         
        </div>

      </div>

      {isLocationDialogOpen && (
        <MapDialog
          isOpen={isLocationDialogOpen}
          onClose={() => setLocationDialogOpen(false)}
          latitude={logEvent.login_latitude}
          longitude={logEvent.login_longitude}
          itemid='1'
        />
      )}

      {isLogoutDistanceDialogOpen && (
        <MapDialog
          isOpen={isLogoutDistanceDialogOpen}
          onClose={() => setLogoutDistanceDialogOpen(false)}
          latitude={logEvent.logout_latitude}
          longitude={logEvent.logout_longitude}
        />
      )}
    </div>
  );
};

export default ReviewLogEvent;
