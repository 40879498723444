 // src/App.js
import React, { useContext } from 'react'; // Correctly import useContext
import { BrowserRouter as Router, Route, Routes, Navigate, Link } from 'react-router-dom';
import './App.css';
import Calendar from './Calendar';
import Timesheets from './Timesheets';
import Staff from './Staff';
import HolidayRota from './holidayrota';
import Organisation from './organisation';
import GroupView from './GroupView'
import Announcements from './announcements';
import Homepage from './Homepage';
import Settings from './Settings';
import Sops from './Sops';
import Planning from './planning';
import Login from './Login'; // Import the Login component
import CrossBranch from './crossbranch';
import logo from './assets/logo.png'; // Import the logo
import { AuthProvider, AuthContext } from './AuthContext'; // Import AuthProvider and AuthContext

const App = () => {
  return (
    <AuthProvider> {/* Wrap your application with AuthProvider */}
      <Router>
        <div className="app-container">
          <header className="app-header">
            <img src={logo} alt="Company Logo" className="logo" />
            <h1>Pharmace Staff Management</h1>
            <div className="header-buttons">
               <Link to="/homepage" className="header-button">Home</Link>
              <Link to="/timesheets" className="header-button">Timesheets</Link>
              <Link to="/calendar" className="header-button">Calendar</Link>
              <Link to="/holidayrota" className="header-button">Holiday Rota</Link>
              <Link to="/planning" className="header-button">Workforce Plan</Link>
              <Link to="/groupview" className="header-button">Group View</Link>
              <Link to="/crossbranch" className="header-button">Cross branch</Link>
              <Link to="/announcements" className="header-button">Announcements</Link>
              <Link to="/settings" className="header-button">Locations</Link>
              <Link to="/staff" className="header-button">Staff</Link>
              <Link to="/organisation" className="header-button">Organisation</Link>
              <Link to="/sops" className="header-button">Sops</Link>
              
            </div>
          </header>
          <main className="app-main">
            <Routes>
              <Route path="/" element={<Navigate to="/login" />} />
              <Route path="/login" element={<Login />} />
              <Route path="/homepage" element={<ProtectedRoute component={Homepage} />} />
              <Route path="/staff" element={<ProtectedRoute component={Staff} />} />
              <Route path="/timesheets" element={<ProtectedRoute component={Timesheets} />} />
              <Route path="/calendar" element={<ProtectedRoute component={Calendar} />} />
              <Route path="/holidayrota" element={<ProtectedRoute component={HolidayRota} />} />
              <Route path="/groupview" element={<ProtectedRoute component={GroupView} />} />
              <Route path="/announcements" element={<ProtectedRoute component={Announcements} />} />
              <Route path="/settings" element={<ProtectedRoute component={Settings} />} />
              <Route path="/crossbranch" element={<ProtectedRoute component={CrossBranch} />} />
              <Route path="/organisation" element={<ProtectedRoute component={Organisation} />} />
              <Route path="/planning" element={<ProtectedRoute component={Planning} />} />
              <Route path="/sops" element={<ProtectedRoute component={Sops} />} />
              <Route path="*" element={<Navigate to="/login" />} />
            </Routes>
          </main>
        </div>
      </Router>
    </AuthProvider>
  );
};

// Component to protect routes
const ProtectedRoute = ({ component: Component }) => {
  const { isLoggedIn } = useContext(AuthContext); // Use useContext to get isLoggedIn
  return isLoggedIn ? <Component /> : <Navigate to="/login" />;
};

export default App;
