import React, { useEffect, useRef, useState } from 'react';
import './reviewHoliday.css';
import axios from 'axios';
import calculateHolidayAllowance from './holidayAllowanceCalculator';
const ReviewHoliday = ({ staffHolidayId, onClose }) => {
    const holidayDataRef = useRef(null);
    const origOrgIdRef = useRef('');
    const origRoleRef = useRef('');
    const origStaffNameRef = useRef('');
    const [managerComment, setManagerComment] = useState(''); 
    const [status, setStatus] = useState(''); 
    const [holidayList, setHolidayList] = useState([]); 
    const workingLimits = useRef('');
    const min = useRef('');
    let holAllowance= useRef();
    let holTaken= useRef();

    useEffect(() => {
        const fetchData = async () => {
            if (staffHolidayId) {
                await fetchThisHoliday(); // Wait for this to complete
            
            }
        };
    
        fetchData(); // Call the async function
    }, [staffHolidayId]); // Run this effect when staffHolidayId changes
    
 

    const getWorkingLimit = ( type, dayOfWeek) => {
        if (!workingLimits) return 0; // Return 0 if workingLimits is not provided
    
        // Create the concatenated key using role and dayOfWeek
        const key = `${origRoleRef.current}-${dayOfWeek}`;
    
        // Find the limit in workingLimits using the key
        const limit = workingLimits.current[key];
    
        // Check the type and return the appropriate value
        if (type === 'minimum') {
            return limit ? limit : 0; // Return the limit or 0 if it doesn't exist
        } else if (type === 'optimum') {
            return limit ? limit * 1.5 : 0; // Return 1.5 times the limit or 0 if it doesn't exist
        }
        
        return 0; // Default return if type is not recognized
    };
    
    

    const fetchThisHoliday = async () => {

         
        const holidaysUrl = `https://heuristic-cray.194-76-27-167.plesk.page/api/staff_holiday?itemid=${staffHolidayId}`;
        const staffResponse = await axios.get(holidaysUrl);
    
                 const holidayData = staffResponse.data[0];

                if (holidayData) {
                    holidayDataRef.current = holidayData; 
                    setManagerComment(""); 
                    setStatus(holidayData.status); 
                    origRoleRef.current = holidayData.role;
                    origOrgIdRef.current = holidayData.organisation_id; 
                    origStaffNameRef.current = holidayData.staff_name; 
                    let yrnumber=new Date().getFullYear();
                    await calculateHolidayAllowance(holidayData.staff_id, yrnumber)
                    .then((holidayAllowance) => {
                      if (holidayAllowance.length > 0) {
                        // Extract hrstaken from the first entry in holidayAllowance
                        holTaken.current = holidayAllowance[0].hrstaken;
                        holAllowance.current = holidayAllowance[0].hrsallowed;
                      } else {
                        // If there's no data for the current year
                        holAllowance.current = 0;
                        holTaken.current=0;
                      }
                    })
                    .catch((error) => {
                      console.error('Error fetching holiday allowance:', error);
                      holAllowance = 0; // Set default value in case of an error
                    });
                  
        
                }
                       const workingLimitsUrl = `https://heuristic-cray.194-76-27-167.plesk.page/api/workinglimits?locationId=${encodeURIComponent(origOrgIdRef.current)}`;
                       const wlResponse = await axios.get(workingLimitsUrl);
    
                     const extractedData =  wlResponse.data[0].data;
            
                    // Convert the extracted 'data' array into a key-pair array
                    const newGridData = {};
                    extractedData.forEach(item => {
                        const key = `${item.role}-${item.day}`; // Create a key based on role and day
                        newGridData[key] = item.value; // Assign the value to the key, ignoring _id
                    });
                
                    // Set the gridData with the newly created key-pair array
                    workingLimits.current=newGridData;
                    processHolidayEntries();
                 

    };

    const processHolidayEntries = () => {
        if (!holidayDataRef.current) return;

        const start = new Date(holidayDataRef.current.start);
        const end = new Date(holidayDataRef.current.end);
        const dateArray = [];
        const orgId = origOrgIdRef.current;
        let dayOfWeek='';
      

        let dailyEntries = [];
        let holstatus="";
        for (let d = new Date(start); d <= end; d.setDate(d.getDate() + 1)) {
            dateArray.push(new Date(d));
        }
       
        let staffHolidaysArray=[];
        const holidaysUrl = `https://heuristic-cray.194-76-27-167.plesk.page/api/staff_holiday_daterange?organisation_id=${orgId}&startDate=${start}&endDate=${end}`;
        axios.get(holidaysUrl)
            .then(holidaysResponse => {
                 staffHolidaysArray = holidaysResponse.data;

                return axios.get(`https://heuristic-cray.194-76-27-167.plesk.page/api/staff?default_location_id=${orgId}`);
            })
            .then(staffResponse => {
                const staffMembersArray = staffResponse.data;
                const combinedArray = []; // Step 1: Initialize a new array
                // Processing each date to get matching holidays and staff entries
                dateArray.forEach(date => {
                    dayOfWeek = date.toLocaleDateString('en-US', { weekday: 'short' });

                    const formattedDateISO = date.toISOString();
                    const matchingHolidays = [];

                    for (const holiday of staffHolidaysArray) {
                        if (holiday.start <= formattedDateISO && holiday.end >= formattedDateISO && (holiday.status=="Approved" || holiday.status=="Requested")) {
                            matchingHolidays.push(holiday);
                        }
                    }
                    matchingHolidays.forEach(holiday => {
                        const staff = staffMembersArray.find((staffMember) => staffMember._id === holiday.staff_id);
                        const shortDayName = date.toLocaleDateString('en-GB', { weekday: 'short' });
                        holstatus=holiday.status;
                        if (staff) {
                            const dailyEntry = staff.dailyEntries.find(
                                (entry) => entry.dayOfWeek === shortDayName && entry.weekNumber === getWeekNumber(date)
                            );
                            let bc='transparent';
                            if (holstatus=='Requested'){
                                    bc='yellow';
                            }

                            if (dailyEntry && dailyEntry.hoursworked >= 1) {
                                dailyEntries.push({
                                    staff_name: staff.firstName + ' ' + staff.lastName,
                                    role: staff.role,
                                    hoursworked: dailyEntry.hoursworked,
                                    holstatus: bc,
                                });
                            }
                        }
                    });
  

// Take daily entries before the next loop
combinedArray.push({
    date,             // the date
    entries: dailyEntries // subarray of dailyEntries
    
});
dailyEntries=[];

                
                });
//sort combined array and make totals
setHolidayList(processCombinedArray(combinedArray,dayOfWeek));
   
     
            })
            .catch(error => {
                console.error('Error processing holiday entries:', error);
            });


    };


 
    const processCombinedArray = (combinedArray,dayOfWeek) => {
      return combinedArray.map((day) => {
        if (day.entries.length === 0) return day;
    
        // Sort entries by role
        day.entries.sort((a, b) => a.role.localeCompare(b.role));
    
        let roleSummary = {};
        let resultEntries = [];
    
        // Group entries by role and calculate total hoursworked
        day.entries.forEach((entry) => {
          if (!roleSummary[entry.role]) {
            roleSummary[entry.role] = {
              staff_name: `${entry.role} total hrs:`, 
              role: entry.role,
              hoursworked: 0, 
              holstatus: 'transparent', // Default, will change based on min comparison
            };
          }
          // Sum hoursworked for this role
          roleSummary[entry.role].hoursworked += entry.hoursworked;
    
          // Push original entry to resultEntries
          resultEntries.push(entry);
        });
    
        // After processing each role, add summary entry and determine holstatus
        Object.values(roleSummary).forEach((summary) => {
          const totalHours = summary.hoursworked;
    
          // Determine holstatus based on total hours and min
          let min=getWorkingLimit("minimum",dayOfWeek)
          if (min === null || min === 0) {
            summary.holstatus = 'transparent';
          } else if (totalHours < min) {
            summary.holstatus = 'lightcoral';
          } else {
            summary.holstatus = 'lightgreen';
          }
    
          // Insert the summary entry into resultEntries after the role's entries if role is same as persons staff role
          if (origRoleRef.current===summary.role){
          resultEntries.push(summary);
        }
        });
    
        // Update the day's entries with sorted and summarized entries
        return {
          ...day,
          entries: resultEntries,
        };
      });
    };
    
     

    const formatDate = (date) => {
        return `${date.toLocaleDateString('en-GB', { weekday: 'short', day: '2-digit', month: '2-digit', year: '2-digit' })}`;
    };

    const getWeekNumber = (date) => {
        const dayOfMonth = date.getDate(); 
        const occurrence = Math.floor((dayOfMonth - 1) / 7) + 1;
        return occurrence === 5 ? 1 : occurrence;
    };

    const handleSave = async () => {
        const updatedHoliday = {
            _id: staffHolidayId,         // Include the holiday ID for updating the correct record
            status,                      // Status of the holiday (Approved or Declined)
            manager_comment: managerComment, // Manager's comment
         };
    
        try {
            // Send POST request to update the holiday details
            await axios.post(`https://heuristic-cray.194-76-27-167.plesk.page/api/staff_holiday_update`, updatedHoliday);
            
            // Close the popup or dialog after successful update
               // Call the parent's refresh function
               if (onClose) {
                onClose();
            }
            onClose();
        } catch (error) {
            console.error('Error updating holiday details:', error);
        }
    };

    

        if (!holidayDataRef.current) {
        return <div>Loading...</div>;
    }

    return (
        <div className="popup-overlay">
            <div className="popup-container">
                <div className="popup-left">
                    <h3>Holiday Details</h3>
                    <p><strong>Staff Name:</strong> {origStaffNameRef.current}</p>
                    <p><strong>Role:</strong> {origRoleRef.current}</p>
                    <p><strong>Start Date:</strong> {formatDate(new Date(holidayDataRef.current.start))}</p>
                    <p><strong>End Date:</strong> {formatDate(new Date(holidayDataRef.current.end))}</p>
                    <p><strong>Type:</strong> {holidayDataRef.current.type}</p>
                    {holidayDataRef.current.type === 'Part day' && (
                        <>
                            <p><strong>Start Time:</strong> {holidayDataRef.current.start_time}</p>
                            <p><strong>End Time:</strong> {holidayDataRef.current.end_time}</p>
                        </>
                    )}
                    <p><strong>Status: </strong> 
                        <select 
                            value={status} 
                            onChange={(e) => setStatus(e.target.value)}
                        >
                            <option value="Requested">Requested</option>
                            <option value="Approved">Approved</option>
                            <option value="Declined">Declined</option>
                        </select>
                    </p>
    
                    <p><strong>Manager Comment: </strong></p>
                    <textarea 
                        value={managerComment}  
                        onChange={(e) => setManagerComment(e.target.value)}  
                    />
    
                    <div className="popup-buttons">
                        <button onClick={handleSave}>Save</button>
                        <button onClick={onClose}>Cancel</button>
                    </div>
              

                <p 
    style={{ 
        backgroundColor: holTaken.current > holAllowance.current ? 'lightcoral' : 'lightgreen', 
        padding: '5px', 
        borderRadius: '4px' 
    }}
>
    <strong>Holiday taken so far:</strong> {holTaken.current} hours 
    <br />
    <strong>Out of allowance:</strong> {holAllowance.current} hours
</p>
</div>
    
                <div className="popup-right">
                    <h3>Holiday Overview</h3>
                    {Array.isArray(holidayList) && holidayList.length > 0 ? (
                        holidayList.map((item, index) => (
                            <div key={index}>
                                {/* Accessing and formatting the date */}
                                <p style={{ fontSize: '10px' }}><strong>{formatDate(new Date(item.date))}</strong></p>
                                {item.entries && item.entries.length > 0 ? (  /* item.entries is the array of entries */
                                    <ul>
                                        {item.entries.map((entry, idx) => (
                                            <li 
                                                key={idx} 
                                                style={{ backgroundColor: entry.holstatus }}
                                            >
                                                {entry.staff_name} ({entry.role}) - {entry.hoursworked} hours
                                            </li>
                                        ))}
                                    </ul>
                                ) : (
                                    <p>No entries for this date.</p>
                                )}
                            </div>
                        ))
                    ) : (
                        <p>No holidays found.</p>
                    )}
                </div>
            </div>
        </div>
    );
            
};

export default ReviewHoliday;
