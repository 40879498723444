import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import './Homepage.css';
import ReviewHoliday from './reviewHoliday'; 
import { AuthContext } from './AuthContext';

import  { useRef } from 'react';
import ReviewLogEvent from './reviewLogEvent.js';

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

const Homepage = () => {
  const { organisationId }  = useContext(AuthContext); // Access organisationId

  const [perfInds, setPerfInds] = useState([]);
  const [perfIndsGraph, setPerfIndsGraph] = useState([]);
  const [selectedHolidayId, setSelectedHolidayId] = useState(null); // State to track which holiday is selected
  const [refreshKey, setRefreshKey] = useState(0);  // State for forcing a re-render
  const [workingLimits, setWorkingLimits] = useState([]); // State for working limits
  const [redDays, setRedDays] = useState([]); // Initialize redDays as state
    const handleRefresh = () => {
        setRefreshKey((prevKey) => prevKey + 1);  // Increment the refresh key to trigger a re-render
    };
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [newIndDate, setNewIndDate] = useState('');
  const [newIndValue, setNewIndValue] = useState('');
  const [showAddDialog, setShowAddDialog] = useState(false);
  const [editingInd, setEditingInd] = useState(null);
  const [staffHolidays, setStaffHolidays] = useState([]);
  const [allStaffHolidays, setAllStaffHolidays] = useState([]);
  const [locums, setLocums] = useState([]);

  const [staffCurrentHolidays, setStaffCurrentHolidays] = useState([]);
  const [logAnomalies, setLogAnomalies] = useState([]);
  const [showReviewDialog, setShowReviewDialog] = useState(false);
  const [selectedLogAnomaly, setSelectedLogAnomaly] = useState(null);

  const [staffSickness, setStaffSickness] = useState([]);
  const staffMembers = useRef([]);
  const [logArraySet, setLogArraySet] = useState([]);
const sHols = useRef();
const sSick= useRef();
const aHols=useRef();
const aWl=useRef();
const sLoc=useRef();
const logArray = useRef([]); // Step 1: Initialize logArray as a useRef
const [showHolidayDialog, setShowHolidayDialog] = useState(false);
  const [selectedHoliday, setSelectedHoliday] = useState(null);
  const [managerComment, setManagerComment] = useState('');
  const [holidayStatus, setHolidayStatus] = useState('Requested');

  
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Step 1: Fetch locations first
        await fetchLocations(organisationId);
       // await fetchAnomalies();
  
        if (selectedLocation) {
          // Step 2: Fetch other data
          await fetchPerfInds();
          
                     
          await fetchAnomalies(organisationId);          
          await fetchStaffHolidays();

          // Step 3: Ensure fetchCurrentHolidays and fetchStaffSickness finish before fetchLogEvents
          await Promise.all([fetchLocums(),fetchWorkingLimits(),fetchAllStaffHolidays(),fetchStaffMembers(organisationId),fetchCurrentHolidays(), fetchStaffSickness()]);
  
          // Step 4: Fetch log events after holidays and sickness have been fetched
          await fetchLogEvents(); // Wait for logArray to be set
  
          // Step 5: Optionally fetch staff members (this step is commented out for now)
          // await fetchStaffMembers(); // Modify logArray using setLogArray
          
          // Step 6: Call getRedDays to calculate red days after all required data is fetched
          getRedDays();  // <--- Call the getRedDays function here
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();
    const intervalId = setInterval(fetchData, 120000);
  
    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  
  }, [refreshKey, selectedLocation]); // Only run when selectedLocation changes
          
 

  const calculateHours = (startTime, endTime) => {
    // Split the time strings into hours and minutes
    const [startHour, startMinute] = startTime.split(":").map(Number);
    const [endHour, endMinute] = endTime.split(":").map(Number);
  
    // Calculate the difference in hours and minutes
    const startTotalMinutes = startHour * 60 + startMinute;
    const endTotalMinutes = endHour * 60 + endMinute;
  
    // Find the total difference in minutes
    const totalMinutes = endTotalMinutes - startTotalMinutes;
  
    // Convert the total minutes difference into hours
    const hrs = totalMinutes / 60;
    
    return hrs.toFixed(1);
  };

  const isValidTimeFormat = (time) => {
    // Regular expression to match HH:MM format
    const timePattern = /^([01]\d|2[0-3]):([0-5]\d)$/;
    return timePattern.test(time);
  };

  const fetchLocations = async (orgId) => {
    try {
//      const locationsUrl = 'https://heuristic-cray.194-76-27-167.plesk.page/api/locations?org=${orgId}';
const locationsUrl = 'https://heuristic-cray.194-76-27-167.plesk.page/api/locations';
    
const response = await axios.get(locationsUrl);
const filteredLocations = response.data.filter(location => location.organisation === orgId);
setLocations(filteredLocations); // Set the filtered locations
      if ((!selectedLocation || selectedLocation==='')  & filteredLocations.length > 0) {
        setSelectedLocation(filteredLocations.data[0]._id);
      }

    } catch (error) {
      console.error('Error fetching locations:', error);
    }
  };


  const fetchStaffMembers = async (orgId) => {
    try {
      const staffUrl = `https://heuristic-cray.194-76-27-167.plesk.page/api/staff?default_location_id=${selectedLocation}`;
      const staffResponse = await axios.get(staffUrl); // Fetch the staff data
      // Filter the staff members based on organisation_id
      staffMembers.current = staffResponse.data.filter(staff => staff.organisation_id === orgId);
         //   //////console.log('Successfully fetched staff members:', staffResponse.data);
    } catch (error) {
      //console.error('Failed to load staff members:', error);
    }
  };

  const fetchPerfInds = async () => {
    if (!selectedLocation){
      return;
    }
    try {
      const params = selectedLocation ? { locationId: selectedLocation } : {};
      const response = await axios.get('https://heuristic-cray.194-76-27-167.plesk.page/api/perfInd', { params });
  
      // Sort the data by date in reverse chronological order
      const sortedPerfInds = response.data.sort((a, b) => {
        const dateA = new Date(a.created).getTime();
        const dateB = new Date(b.created).getTime();
        return dateB - dateA; // Reverse chronological order based on 'created' field
      });
      
      setPerfInds(sortedPerfInds);
    
     // Sort a new array for the graph in chronological order
     const perfIndsGraph = [...response.data].sort((a, b) => {
      const dateA = new Date(a.created).getTime();
      const dateB = new Date(b.created).getTime();
      return dateA - dateB; // Chronological order based on 'created' field
    });

    // Save this sorted array for the graph
    setPerfIndsGraph(perfIndsGraph);
    
    } catch (error) {
      console.error('Error fetching performance indicators:', error);
    }

  };
  

  const fetchWorkingLimits = async () => {
    try {
      const workingLimitsUrl = `https://heuristic-cray.194-76-27-167.plesk.page/api/workinglimits?locationId=${encodeURIComponent(selectedLocation)}`;
      const response = await axios.get(workingLimitsUrl);
  
      const workingLimitData = response.data;

      // Check if working limit data exists for the selected location
     
          // Extract the 'data' array from the first element of the response
          const extractedData = workingLimitData[0].data;
      
          // Convert the extracted 'data' array into a key-pair array
          const newGridData = {};
          extractedData.forEach(item => {
              const key = `${item.role}-${item.day}`; // Create a key based on role and day
              newGridData[key] = item.value; // Assign the value to the key, ignoring _id
          });
      
   
      setWorkingLimits(newGridData);
      aWl.current=newGridData;
    } catch (error) {
      // console.error('Failed to load working limits:', error);
    }
  };


  const fetchStaffHolidays = async (orgId) => {
    try {
      const params = {
        organisation_id: selectedLocation,
        status: "Requested"
      };
      const response = await axios.get('https://heuristic-cray.194-76-27-167.plesk.page/api/staff_holiday', { params });
      setStaffHolidays(response.data);
   
    } catch (error) {
      console.error('Error fetching staff holidays:', error);
    }
  };

  const fetchAllStaffHolidays = async () => {
    try {
      const params = {
        organisation_id: selectedLocation 
      };
      const response = await axios.get('https://heuristic-cray.194-76-27-167.plesk.page/api/staff_holiday', { params });
      setAllStaffHolidays(response.data);
      aHols.current=response.data;
    } catch (error) {
      console.error('Error fetching staff holidays:', error);
    }
  };

  const fetchLocums = async () => {
    try {
 
      const staffUrl = `https://heuristic-cray.194-76-27-167.plesk.page/api/locums?location_id=${encodeURIComponent(selectedLocation)}`;
      const response = await axios.get(staffUrl);    
//      const filtered = response.data.filter(
  //      (locum) => new Date(locum.start) <= new Date(dialogDate) && new Date(locum.end) >= new Date(dialogDate)
   //   );
      
  setLocums(response.data); 
  sLoc.current=response.data;
  

} catch (error) {
  console.error('Error fetching locums:', error);
}
};


  const calculateCellData = (date) => {
    const dayOfWeek = date.toLocaleDateString('en-GB', { weekday: 'short' });
    const weekNumber = Math.ceil(date.getDate() / 7) === 5 ? 1 : Math.ceil(date.getDate() / 7);
  
    const roles = Array.from(new Set(staffMembers.current.map(member => member.role)));
    let columns = roles.map(role => {
      let totalHours = 0;
      let status = null; // Initialize status early
  
      // Calculate total hours worked for this role on the specific day
   // Filter staff members based on selectedLocation and role
   const filteredStaff = staffMembers.current.filter(
    member => member.default_location_id === selectedLocation && member.role === role
  );
  
// Iterate through the filtered staff members to calculate total hours
filteredStaff.forEach(member => {
  member.dailyEntries.forEach(entry => {
    if (entry.dayOfWeek === dayOfWeek && entry.weekNumber === weekNumber && entry.startTime !== "00:00") {
      totalHours += entry.hoursworked;
    }
  });
});

    
      // Keep track of whether there were any hours initially at all
      let fullHours = totalHours;
  
      // Subtract hours based on staff holidays
      aHols.current.forEach(holiday => {
        if (holiday.start <= date.toISOString().split('T')[0] && holiday.end >= date.toISOString().split('T')[0]) {
          if (holiday.role === role) {
            staffMembers.current.forEach(member => {
              if (member._id === holiday.staff_id) {
                member.dailyEntries.forEach(entry => {
                  if (entry.dayOfWeek === dayOfWeek && entry.weekNumber === weekNumber && entry.hoursworked>0) {
                    totalHours -= entry.hoursworked;
                  }
                });
              }
            });
          }
        }
      });
  
//process locms
sLoc.current.forEach(locum => {
  if (locum.start <= date.toISOString().split('T')[0] && locum.end >= date.toISOString().split('T')[0]) {
    if (locum.role === role) {
      //calculate how many hours worked
    
      if (isValidTimeFormat(locum.startTime) && isValidTimeFormat(locum.endTime)) {
        totalHours += calculateHours(locum.startTime, locum.endTime);
      } else {
        console.error("Invalid time format for startTime or endTime.");
      }
    }
  }
});



//for testing
 
      // If fullHours is 0, no working hours for this role type on this day
      if (fullHours === 0) {
  
        status = -2;
        totalHours = null;
        //console.log(`either fulllhours is 0 or no working hours for tis role on this day...${role} ${totalHours} `);

        return {
          role,
          totalHours,
          status
        };
      }
  
      // Determine the status based on working limits
      const workingLimitKey = `${role}-${dayOfWeek}`; // Construct the key
      const workingLimit = aWl.current[workingLimitKey]; // Get the corresponding value from useRef

//      const workingLimit = workingLimits[workingLimitKey]; // Get the corresponding value
   
      // If no working limit is set or minimum is 0, return early with null status
      if (!workingLimit || workingLimit === 0) {
  
        status = -2;
        return {
          role,
          totalHours,
          status
        };
      }
  
      const optimum=workingLimit*1.5;
   
      // Calculate status based on the working limits
      if (totalHours < workingLimit) {
        status = 0;  // Below minimum
      } else if (totalHours >= workingLimit && totalHours <= optimum) {
        status = 1;  // Between minimum and optimum
      } else if (totalHours > optimum) {
        status = 2;  // Above optimum
      }
  
      //console.log(`calculated outcome is...${role} ${totalHours} ${status}`);

      return {
        role,
        totalHours,
        status
      };
    });
  
    return columns;
  };
  

  const getRedDays = () => {
    const today = new Date();
    const futureDate = new Date();
    
    // Set the future date to three months ahead
    futureDate.setMonth(futureDate.getMonth() + 3);
  
    // Variables to accumulate the number of red days
    let redDayCount = 0;
    let redDayCountTwoWeeks = 0;
  
    // Iterate through each day from today to three months in the future
    for (let date = new Date(today); date <= futureDate; date.setDate(date.getDate() + 1)) {
      const cellData = calculateCellData(new Date(date)); // Call calculateCellData for this date
  
      // Loop through the results for each role and check if the status is 0
      cellData.forEach(({ status }) => {
        if (status === 0) {
          redDayCount++; // Increment the count if status is 0
  
          // Check if the date is within 14 days of today
          if (date <= new Date(today.getTime() + 14 * 24 * 60 * 60 * 1000)) {
            redDayCountTwoWeeks++;
          }
        }
      });
    }
  
    // Determine the background color based on the count of red days
    const backgroundColor = redDayCount > 0 ? 'lightcoral' : 'transparent';
  
    // Create the message based on the red day counts with a newline character
    const message = redDayCount === 0
      ? 'There are no red days in the next three months.'
      : `There are ${redDayCount} red days in the next three months.\n` +
        `There are ${redDayCountTwoWeeks > 0 ? redDayCountTwoWeeks : 'none'} occurring in the next two weeks.`;
  
    // Update the redDays state with the count, message, and background color
    setRedDays({ count: redDayCount, message, backgroundColor });
  };
  
  const refreshLogAnomalies = () => {
   fetchAnomalies(organisationId);
  };

  const fetchCurrentHolidays = async () => {
    const today = new Date().toISOString().split('T')[0];
     try {
      const params = {
        organisation_id: selectedLocation,
        startDate: today,
       endDate: today,
        status: "Approved"
      };

     // const holidaysUrl = `https://heuristic-cray.194-76-27-167.plesk.page/api/staff_holiday_daterange?organisation_id=${orgId}&startDate=${start}&endDate=${end}`;
 


     const response = await axios.get('https://heuristic-cray.194-76-27-167.plesk.page/api/staff_holiday_daterange', { params });
     // Filter the response data based on organisation_id
     const filteredHolidays = response.data;
     setStaffCurrentHolidays(filteredHolidays); // Set the state with filtered data
     sHols.current = filteredHolidays; // Assign filtered data to ref
     

    } catch (error) {
      console.error('Error fetching staff holidays:', error);
    }
  };


  const openLogEventReview = (anomaly) => {
    setSelectedLogAnomaly(anomaly);  // This will store the selected anomaly in the state
    setShowReviewDialog(true);       // This will trigger the dialog to open
  };
  

  const handleDialogClose = () => {
    setShowReviewDialog(false);
    // Logic to refresh the logAnomalies grid if needed
    refreshLogAnomalies(); // Refresh function
  };

  const fetchStaffSickness = async () => {
    try {
      const params = {
        organisation_id: selectedLocation,
        status: 'Open'
      };
      const response = await axios.get('https://heuristic-cray.194-76-27-167.plesk.page/api/staff_sickness', { params });
      setStaffSickness(response.data);
      sSick.current=response.data;
    } catch (error) {
      console.error('Error fetching staff sickness:', error);
    }
  };

  const formatAsTime = (dateString) => {
    const date = new Date(dateString);
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${hours}:${minutes}`;
  };

 // const fetchStaffMembers = async () => {
    
 // };
    
  const fetchLogEvents = async (orgId) => {
    setLogArraySet([]);

    try {
      const today = new Date().toISOString().split('T')[0];
    const params = {
       location: selectedLocation,
       organisation_id: orgId, //,
    //   log_status: 'Logged in',  //,
        date: today
      };
      const response = await axios.get('https://heuristic-cray.194-76-27-167.plesk.page/api/staff_log_events_today', { params });
      logArray.current = response.data; // Assign response.data to the current value of logArray

    } catch (error) {
      console.error('Error fetching log events:', error);
    }



    try {
      const params = { default_location_id: selectedLocation };
      const response = await axios.get('https://heuristic-cray.194-76-27-167.plesk.page/api/staff', { params });
      let members = response.data;
  
      const today = new Date();
      const dayOfWeek = today.toLocaleString('en-GB', { weekday: 'short' });
      const weekNumber = Math.ceil(today.getDate() / 7) % 5 || 1;
  
      const newEntries = [];
  
      members = members.map(member => {
        const staffId = member._id;
  

      //  const isOnHoliday = staffCurrentHolidays.some(holiday => holiday.staff_id === staffId.toString());
      //  const isSick = staffSickness.some(sickness => sickness.staff_id === staffId);
      const isOnHoliday = sHols.current.some(holiday => holiday.staff_id === staffId.toString());
      const isSick = sSick.current.some(sickness => sickness.staff_id === staffId);
      
        if (isOnHoliday || isSick) {
          return member; 
        }
  
        const logEntry = logArray.current.find(log => log.staff_id === staffId.toString()); // Use logArray from refs
        let eventtype = '';
  
        if (logEntry) {
          if (logEntry.log_status === "Logged in") {
            eventtype = `Arrived at ${formatAsTime(logEntry.actual_login)} - ${logEntry.planned_logout}`;
          } else {
            eventtype = `Left at ${formatAsTime(logEntry.actual_logout)}`;
          }
          const event = {
            staff_name: `${member.firstName} ${member.lastName} (${member.role} )`,
            event_type: eventtype,
            planned_login: '',
            staff_id: staffId
          };
          newEntries.push(event);
        } else {
          const dayEntry = member.dailyEntries.find(
            entry => entry.dayOfWeek === dayOfWeek && entry.weekNumber === weekNumber
          );
  
          if (dayEntry && dayEntry.startTime !== '00:00') {
            const event = {
              staff_name: `${member.firstName} ${member.lastName} (${member.role} )`,
              event_type: `Due in ${dayEntry.startTime}`,
              planned_login: dayEntry.startTime,
              staff_id: staffId
            };
            newEntries.push(event);
          }
        }
  
        return member;
      });
  
      setLogArraySet(prevLogArraySet => [...prevLogArraySet, ...newEntries]);

      //logArray.current = [...logArray.current, ...newEntries]; // Update logArray using refs
    } catch (error) {
      console.error('Error fetching staff members:', error);
    }


  };


  const fetchAnomalies = async (orgId) => {
    try {
      const params = {
        location: selectedLocation,
        organisation_id: orgId,
 //       date: new Date().toISOString().split('T')[0] // Use today's date if needed
      };
  
      const response = await axios.get('https://heuristic-cray.194-76-27-167.plesk.page/api/staff_log_events_anomalies', { params });
      setLogAnomalies(response.data);
    } catch (error) {
      console.error('Error fetching log events:', error);
    }
  };
  

  const handleLocationChange = (e) => {
    setSelectedLocation(e.target.value);
  };

  const formatDate = (input) => {
    if (!input) return 'Invalid Date';
    const date = (typeof input === 'string') ? new Date(input) : input;
    if (isNaN(date.getTime())) {
      return 'Invalid Date';
    }
    return date.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: '2-digit',
    });
  };

  const openHolidayDialog = (holidayId) => {
    setSelectedHolidayId(holidayId); // Set the selected holiday ID
};

// Function to close the popup
const closeReviewHolidayPopup = () => {
    setSelectedHolidayId(null); // Reset the holiday ID to close the popup
    handleRefresh();
};
 

  const openAddDialog = (ind = null) => {
    if (ind) {
      setNewIndDate(ind.date);
      setNewIndValue(ind.value);
      setEditingInd(ind);
    } else {
      setNewIndDate('');
      setNewIndValue('');
      setEditingInd(null);
    }
    setShowAddDialog(true);
  };

  const handleSaveNewInd = async () => {
    try {
      const newPerformanceIndicator = {
        created: newIndDate,
        value: newIndValue,
        locationId: selectedLocation,
      };
      await axios.post('https://heuristic-cray.194-76-27-167.plesk.page/api/perfInd', newPerformanceIndicator);
      setNewIndDate('');
      setNewIndValue('');
      setShowAddDialog(false);
      fetchPerfInds();
    } catch (error) {
      console.error('Error posting new performance indicator:', error);
    }
  };

  const savePerformanceInd = () => {
    if (editingInd) {
      console.log('Updating indicator:', editingInd.id, newIndDate, newIndValue);
    } else {
      console.log('Adding new indicator:', newIndDate, newIndValue);
      handleSaveNewInd();
    }
  };

  const Modal = ({ isOpen, onClose, children }) => {
    if (!isOpen) return null;
    return (
      <div className="modal-overlay">
        <div className="modal">
          <button className="close-button" onClick={onClose}>X</button>
          {children}
        </div>
      </div>
    );
  };

  return (
    <div className="homepage">
     <div className="filter-container" style={{ fontSize: '18px' }}>
  <label 
    htmlFor="locationSelect" 
    style={{ marginRight: '10px', fontSize: '14px' }} // Match the font size of the label
  >
    Select location:
  </label>
  <select
    id="locationSelect"
    value={selectedLocation ? selectedLocation : ''}
    onChange={handleLocationChange}
    style={{
      fontSize: '14px',   // Match font size with label
      height: '28px',     // Set the height to match the label size
      padding: '4px',     // Add padding for consistency with the previous block
    }}
  >
    <option value="">Select Location</option>
    {locations.map((location) => (
      <option key={location._id} value={location._id}>
        {location.name}
      </option>
    ))}
  </select>
</div>

      <div className="main-grid">


        {/* Staff Sickness Records and Log Events */}
        <div className="staff-sickness-records">
        <h2>Staff on holiday</h2>
          <ul className="holiday-list">
            {staffCurrentHolidays.length === 0 ? (
              <li>No staff on holiday</li>
            ) : (
              staffCurrentHolidays.map((record) => (
                <li key={record._id}>
                  {`${record.staff_name} (${record.role} )- ${formatDate(record.start)} to ${formatDate(record.end)}`}
                </li>
              ))
            )}
          </ul>



          <h2>Staff on sick leave</h2>
          <ul className="sickness-list">
            {staffSickness.length === 0 ? (
              <li>No staff off sick</li>
            ) : (
              staffSickness.map((record) => (
                <li key={record._id}>
                  {`${record.staff_name} (${record.role} )-   from ${formatDate(record.created)}`}
                </li>
              ))
            )}
          </ul>
  
{/* Log Events Section */}
<h2>Today's staffing</h2>
<ul className="log-events-list">
 {logArraySet.length === 0 ? (

    <li>No staff due in today</li>
  ) : (
    logArraySet.map((event, index) => {



      // Get the current date and time
      const currentTime = new Date();
      // Split planned_login into hours and minutes
      const [loginHours, loginMinutes] = event.planned_login.split(':');
      // Create a new date object for the planned login time
      const plannedLoginTime = new Date(currentTime.getFullYear(), currentTime.getMonth(), currentTime.getDate(), loginHours, loginMinutes);
      
        let backgroundColor='';
      // Check if planned login time is before the current time
      if (event.planned_login){
        backgroundColor = (plannedLoginTime < currentTime && event.event_type && event.event_type.includes('Due in')) ? 'lightcoral' : 'transparent';
      }
      else {
          backgroundColor='lightgreen';
      }
      return (
        <li key={index} style={{ backgroundColor }}>
          {event.staff_name} - {event.event_type}
        </li>
      );
    })
  )}
</ul>

 
         </div>
  
        <div className="column performance-indicators">
  <div className="performance-container">
    <h3>Performance Indicators</h3>
    <button onClick={() => openAddDialog()}>Add item count</button>
  </div>
   
  <div style={{ marginTop: '20px' }}>
  <ResponsiveContainer width="100%" height={275}>
    <LineChart data={perfIndsGraph}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis
        dataKey="created"
        tickFormatter={(tick) => formatDate(tick)}
        angle={-35}
        textAnchor="end"
      />
      <YAxis />
      <Tooltip 
        content={({ payload }) => {
          if (payload && payload.length) {
            return (
              <div className="custom-tooltip">
                <p>{`Item count: ${payload[0].value}`}</p>
              </div>
            );
          }
          return null;
        }}
      />
      <Legend />
      <Line
        type="monotone"
        dataKey="value"
        stroke="#82ca9d"
        activeDot={{
          onClick: (e, index) => {
            const clickedPoint = perfIndsGraph[index];
            // Open the edit dialog with the clicked performance indicator
            openAddDialog(clickedPoint);
          }
        }}
      />
    </LineChart>
  </ResponsiveContainer>
</div>


  </div>
  
        <div className="column staff-holidays">
  <h3>Holidays awaiting approval</h3>
  <div className="grid-container bordered">
    {staffHolidays.length === 0 ? (
      <div>There are no holidays to review.</div>
    ) : (
      staffHolidays.map((holiday) => (
        <div key={holiday.id} className="grid-item">
          <span>{holiday.staff_name} - {holiday.type}</span>
          <span>{formatDate(holiday.start)} - {formatDate(holiday.end)}</span> {/* Format start date */}
          
          <button onClick={() => openHolidayDialog(holiday._id)}>Review</button>
        </div>
      ))
    )}
  </div>

{/* Log events to review section inside the same column */}
<h3>Log events to review</h3>
<div className="log-events-grid bordered">
  {logAnomalies.length === 0 ? (
    <div>No log events to review.</div>
  ) : (
    logAnomalies.map((anomaly, index) => (
      <div key={index} className="log-event-item" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
          <div style={{ marginRight: '10px' }}>{formatDate(anomaly.created)}</div>
          <div style={{ marginRight: '10px' }}>{anomaly.staff_name}</div>
          <div>
            {anomaly.login_variance > 15 && ' Late login '}
            {anomaly.logout_variance < 0 && ' Early logout '}
            {anomaly.overtime_requested && ' Overtime requested '}
          </div>
        </div>
        <button 
          onClick={(e) => {
            e.stopPropagation(); // Prevent the parent div's onClick from firing
            openLogEventReview(anomaly);
          }}
          style={{ marginLeft: '10px' }} 
        >
          REVIEW
        </button>
      </div>
    ))
  )}
</div>


{/* Example grid rendering */}
<h2>Red Days</h2>
<div
  className="red-days-grid"
  style={{
    backgroundColor: redDays.backgroundColor, // Set the background color dynamically
    padding: '10px',  // Optional padding for better visual appearance
    borderRadius: '8px',  // Optional border-radius for styling
    whiteSpace: 'pre-wrap',  // To ensure the new line character is respected
  }}
>
  <p>{redDays.message}</p>
</div>



</div>

</div>

{/* Add/Edit Performance Indicator Modal */}
{showAddDialog && (
  <div className="popup-overlay" onClick={() => setShowAddDialog(false)}>
    <div className="popup" onClick={(e) => e.stopPropagation()}> {/* Prevents click from closing the modal */}
      <h4>{editingInd ? 'Edit Performance Indicator' : 'Add Performance Indicator'}</h4>
      <div>
        <label>Date:</label>
        <input
          type="date"
          value={newIndDate}
          onChange={(e) => setNewIndDate(e.target.value)}
        />
      </div>
      <div>
        <label>Item count:</label>
        <input
          type="number"
          value={newIndValue}
          onChange={(e) => setNewIndValue(e.target.value)}
        />
      </div>
      <button onClick={savePerformanceInd}>
        {editingInd ? 'Save Changes' : 'Add'}
      </button>
      <button onClick={() => setShowAddDialog(false)} style={{ marginLeft: '10px' }}>
        Close
      </button>
    </div>
  </div>
)}
  

   {/* Conditionally render the ReviewHoliday component when a holiday is selected */}
   {selectedHolidayId && (
                <ReviewHoliday 
                    staffHolidayId={selectedHolidayId} 
                    onClose={closeReviewHolidayPopup} 
                />
            )}

      {/* Review Log Event Dialog */}
      {showReviewDialog && (
        <ReviewLogEvent
          isOpen={showReviewDialog}
          onClose={handleDialogClose}
          logEvent={selectedLogAnomaly}
        />
      )}
  
    </div>
  );
  
};

export default Homepage;
