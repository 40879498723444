import './fullLocums.css'; // Optional: Add styles for your popup here
import axios from 'axios';
import React, { useState, useEffect, useRef } from 'react';

const FullLocums = ({ isOpen, onClose, selectedLocation }) => {
  const [locums, setLocums] = useState([]); // Hooks are now at the top level
  
  const fetchLocums = async () => {
    try {
      const staffUrl = `https://heuristic-cray.194-76-27-167.plesk.page/api/locums?location_id=${encodeURIComponent(selectedLocation)}`;
      const response = await axios.get(staffUrl);
  
      // Sort the locums in reverse chronological order based on the start field
      const sortedLocums = response.data.sort((a, b) => new Date(b.start) - new Date(a.start));
      
      setLocums(sortedLocums); // Set the sorted locums data
  
    } catch (error) {
      console.error('Error fetching locums:', error);
    }
  };

  useEffect(() => {
    if (isOpen) { // Only fetch locums when the modal is open
      fetchLocums();
    }
  }, [isOpen, selectedLocation]); // Fetch locums when selectedLocation or isOpen changes

  // Helper function to format date as dd/mm/yyyy
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  // Early return if the modal is not open
  if (!isOpen) return null;

  return (
    <div className="locum-popup-overlay">
      <div className="locum-popup">
        <h2>Locum Bookings</h2>
        
        {/* Locums Data Display */}
        <div className="locum-grid-box">
          <div className="locum-grid">
            <div className="locum-grid-header">Staff Name</div>
            <div className="locum-grid-header">Start Date</div>
            <div className="locum-grid-header">End Date</div>
            <div className="locum-grid-header">Start Time</div>
            <div className="locum-grid-header">End Time</div>
            <div className="locum-grid-header">Role</div>
            <div className="locum-grid-header">Comment</div>
            
            {locums.map((locum, index) => (
              <React.Fragment key={index}>
                <div className="locum-grid-item">{locum.staff_name}</div>
                <div className="locum-grid-item">{formatDate(locum.start)}</div>
                <div className="locum-grid-item">{formatDate(locum.end)}</div>
                <div className="locum-grid-item">{locum.startTime}</div>
                <div className="locum-grid-item">{locum.endTime}</div>
                <div className="locum-grid-item">{locum.role}</div>
                <div className="locum-grid-item">{locum.comment}</div>
              </React.Fragment>
            ))}
          </div>
        </div>
        
        <button onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default FullLocums;
