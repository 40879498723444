import axios from 'axios';

const getWeekNumber = (date) => {
  const dayOfMonth = date.getDate();
  const occurrence = Math.floor((dayOfMonth - 1) / 7) + 1;
  return occurrence === 5 ? 1 : occurrence;
};

const calculateHolidayAllowance = async (staffId, year) => {
  try {
    // Step 1: Retrieve staff member information
    const staffUrl = `https://heuristic-cray.194-76-27-167.plesk.page/api/staff?itemid=${encodeURIComponent(staffId)}`;
    const staffResponse = await axios.get(staffUrl);
    const staffMember = staffResponse.data;

    if (!staffMember || staffMember.length === 0) {
      console.error(`No staff member found with ID ${staffId}`);
      return [];
    }


    //calculate holiday allowance
    let holallow=(staffMember[0].contracted_hours_per_month/staffMember[0].holallowance*40)*staffMember[0].holallowance*40;
    if (holallow>staffMember[0].holallowance*40){
        holallow=staffMember[0].holallowance*40;
    }
    // Step 2: Retrieve staff holidays information
    let holidaysUrl = `https://heuristic-cray.194-76-27-167.plesk.page/api/staff_holiday?staff_id=${encodeURIComponent(staffId)}`;
    if (year) {
      holidaysUrl += `&year=${encodeURIComponent(year)}`;
    }
    const holidaysResponse = await axios.get(holidaysUrl);
    const staffHolidays = holidaysResponse.data;

    // Initialize holiday allowance array
    let holidayAllowance = [];

    // Step 3: Process each holiday entry
    staffHolidays.forEach((holiday) => {
        if (holiday.status !== 'Declined' && !holiday.notfromallowance==true) {
          const startDate = new Date(holiday.start);
          const endDate = new Date(holiday.end);
      
          // Check if it's a single-day holiday (start === end)
          if (startDate.getTime() === endDate.getTime()) {
            // Calculate the number of hours between from_time and to_time
            const fromTime = new Date(`1970-01-01T${holiday.from_time}`);
            const toTime = new Date(`1970-01-01T${holiday.to_time}`);
            const hoursWorked = (toTime - fromTime) / (1000 * 60 * 60); // Convert milliseconds to hours
      
            // Find or create a row in holidayAllowance for the correct year
            const holidayYear = startDate.getFullYear();
            let allowanceEntry = holidayAllowance.find((entry) => entry.year === holidayYear);
      
            if (!allowanceEntry) {
              allowanceEntry = { year: holidayYear, hrstaken: 0, hrsallowed: holallow };
              holidayAllowance.push(allowanceEntry);
            }
      
            // Update the hours taken for the corresponding year
            allowanceEntry.hrstaken += hoursWorked;
          } else {
            // Multi-day holiday processing
            let currentDate = startDate;
            while (currentDate <= endDate) {
              const dayOfWeek = currentDate.toLocaleDateString('en-US', { weekday: 'short' }); // e.g., 'Mon', 'Tue', etc.
              const weekNumber = getWeekNumber(currentDate);
      
              // Find the corresponding daily entry in the staff member schedule
              const dailyEntry = staffMember[0].dailyEntries.find(
                (entry) => entry.dayOfWeek === dayOfWeek && entry.weekNumber === weekNumber
              );
      
              if (dailyEntry) {
                const hoursWorked = dailyEntry.hoursworked || 0;
      
                // Find or create a row in holidayAllowance for the correct year
                const holidayYear = currentDate.getFullYear();
                let allowanceEntry = holidayAllowance.find((entry) => entry.year === holidayYear);
      
                if (!allowanceEntry) {
                  allowanceEntry = { year: holidayYear, hrstaken: 0, hrsallowed: holallow };
                  holidayAllowance.push(allowanceEntry);
                }
      
                // Update the hours taken for the corresponding year
                allowanceEntry.hrstaken += hoursWorked;
              }
      
              // Move to the next day
              currentDate.setDate(currentDate.getDate() + 1);
            }
          }
        }
      });
      
    return holidayAllowance;
  } catch (error) {
    console.error('Error fetching data:', error);
    return [];
  }
};

export default calculateHolidayAllowance;
