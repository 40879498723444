 // src/Login.js
import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import './login.css'; // Import the CSS file
const Login = () => {
  const { setIsLoggedIn, setOrganisationId } = useContext(AuthContext);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleLogin = (e) => {
    e.preventDefault();

    // Dummy authentication logic
    let orgId;
    if (username === 'Lyneham' && password === 'Pharmace2023') {
      orgId = '66c285a67a5d05eff58e0109';
    } else if (username === 'Greenlight' && password === 'Gr2023') {
      orgId = '66f2a50aeaaa22da74c9dd80';
    }
   else if (username === 'Southtown' && password === 'Pharmace2023') {
    orgId = '66f84ac4fd5c9470d1ae71bf';
  }


    if (orgId) {
      setIsLoggedIn(true); // This updates the context state
      setOrganisationId(orgId);
      navigate('/homepage'); // Navigate to homepage
    } else {
      // Optionally, you can handle invalid login attempts here
      alert('Invalid username or password');
    }
  };

  return (
    <div className="login-container">
       <form onSubmit={handleLogin}>
        <div style={{ marginBottom: '20px', paddingTop: '30px' }}> {/* Padding and margin styles */}
          <label>Username:</label>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </div>
        <div style={{ marginBottom: '20px', paddingTop: '5px' }}> {/* Padding and margin styles */}
          <label>Password:</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit">Login</button>
      </form>
    </div>
  );
};

export default Login;