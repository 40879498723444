import React from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import './map.css';
import axios from 'axios';

const MapDialog = ({ isOpen, onClose, latitude, longitude, loginlocation }) => {
  if (!isOpen) return null;


  //const login=loginlocation;
  const center = { lat: latitude, lng: longitude };
  const mapContainerStyle = {
    height: "400px",
    width: "100%"
  };

  // Define the validate and invalidate functions
  const handleValidateLocation = async (validated) => {
    let vl="N";
    if (validated){vl="Y";}
    try {
        const payload = {
            latitude: latitude,   // Send latitude
            longitude: longitude, // Send longitude
            validated: validated  // Send validated status (true)
        };

        // Make the API call to update the location data
        try {
            const response = await axios.post('https://heuristic-cray.194-76-27-167.plesk.page/api/location_update', payload);

            // Handle successful response (optional, depends on how you want to use the response)
            console.log('Location updated successfully:', response.data);
        } catch (error) {
            console.error('Error updating location:', error);
        }

    } catch (error) {
        console.error('Error in handleValidateLocation:', error);
    }
};

  const invalidate = () => {
    console.log('Location invalidated');
    // Add your invalidation logic here
  };

  return (
    <LoadScript googleMapsApiKey="AIzaSyDSOXhWqgQRNvcZaF0FaOSKEGZO48wISSU">
      <div className="dialog-overlay">
        <div className="dialog">
          <h4>Map Location</h4>
          <GoogleMap
            mapContainerStyle={mapContainerStyle}
            center={center}
            zoom={15}
          >
            <Marker position={center} />
          </GoogleMap>
          <div className="button-group">
          {loginlocation === 'Y' && (
  <>
    <button onClick={() => handleValidateLocation(true)}>Validate Location</button>
    <button onClick={() => handleValidateLocation(false)}>Invalidate Location</button>
  </>
)}
            <button onClick={onClose}>Close</button>
          </div>
        </div>
      </div>
    </LoadScript>
  );
};

export default MapDialog;
